import React, { useState } from "react";
import { useWhisper } from "@chengsokdara/use-whisper";
import { TbMicrophone } from "react-icons/tb";
import { useEffect } from "react";

function SpeechRecognition({ setUserMessage }) {
  const [isRecording, setIsRecording] = useState(false);
  const {
    transcript,
    speaking,
    recording,
    transcribing,
    startRecording,
    stopRecording,
  } = useWhisper({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY, // YOUR_OPEN_AI_TOKEN
    nonStop: true, // keep recording as long as the user is speaking
    streaming: true,
    timeSlice: 1_000,

    config: {
      prompt: "Cuéntame. ¿De qué quieres hablar?",
      language: "es",
      temperature: 0.1,
    },
    stopTimeout: 5000, // auto stop after 5 seconds
    removeSilence: true, // use ffmpeg-wasp to remove silence from recorded speech
  });

  const handleRecording = (event) => {
    event.preventDefault();
    if (recording) {
      stopRecording();
      //while (recording || speaking || transcribing) {}
      //setUserMessage(transcript.text);
    } else {
      startRecording();
    }
  };
  useEffect(() => {
    if (recording || speaking || transcribing) {
      setUserMessage(transcript.text);
    }
  }, [transcript.text]);

  return (
    <div className="h-100 px-0">
      {/* 
      <p>Recording: {String(recording)}</p>
      <p>Speaking: {String(speaking)}</p>
      <p>Transcribing: {String(transcribing)}</p>
      <p>Transcribed Text: {transcript.text}</p>*/}
      <button
        id="voice-input-btn"
        className={`btn h-100  ${recording ? "recording" : ""}`}
        onClick={handleRecording}
      >
        <TbMicrophone size="24px" />
      </button>
    </div>
  );
}

export default SpeechRecognition;
