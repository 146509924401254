import { useQuery } from "react-query";
import axios from "axios";

async function testAPI() {
  return fetch("http://localhost:5000" + "/test_endpoint").then((response) =>
    response.json()
  );
}

export function useTestQuery() {
  return useQuery({
    queryKey: ["test"],
    queryFn: () => testAPI(),
    staleTime: 60000,
  });
}

// POST REQUESTS
export async function getCorrections({
  userMessage,
  sourceLanguage,
  targetLanguage,
  isSuggestion,
  dispatch,
}) {
  fetch(
    process.env.REACT_APP_API_URL +
      "/get_corrections?" +
      new URLSearchParams({
        userMessage: userMessage,
        sourceLanguage: sourceLanguage,
        targetLanguage: targetLanguage,
        isSuggestion: isSuggestion,
      })
  )
    .then((response) => response.json())
    .then((response) => {
      dispatch({ type: "UPDATE_USER_MSG", payload: response });
    });
}

export async function sendMessage({
  chatHistory,
  sourceLanguage,
  targetLanguage,
  currentTopic,
  isSuggestion, //this one will later go on the useCorrecitonsQuery
}) {
  const optionsSendMessage = {
    method: "POST",
    body: JSON.stringify({
      chatHistory: chatHistory,
      sourceLanguage: sourceLanguage,
      targetLanguage: targetLanguage,
      currentTopic: currentTopic,
      isSuggestion: isSuggestion,
    }),
    headers: { "Content-Type": "application/json" },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/send_message`,
    optionsSendMessage
  ).then((response) => response.json());
}

export async function sendTutorMessage({
  targetLanguage,
  currentTopic,
  tutorCommand,
  userQuestion,
}) {
  // Talking to the tutor will have its own functions cause I might expand it later

  const optionsSendTutorMessage = {
    method: "POST",
    body: JSON.stringify({
      targetLanguage: targetLanguage,
      currentTopic: currentTopic,
      tutorCommand: tutorCommand,
      userQuestion: userQuestion,
    }),
    headers: { "Content-Type": "application/json" },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/send_tutor_message`,
    optionsSendTutorMessage
  ).then((response) => response.json());
}

export async function saveChat({ currentUser, currentChat, targetLanguage }) {
  const optionsSaveChat = {
    method: "POST",
    body: JSON.stringify({
      userId: currentUser.uid,
      currentChat: currentChat,
    }),
    headers: { "Content-Type": "application/json" },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/save_chat`,
    optionsSaveChat
  ).then((response) => response.json());
}

export async function unsaveChat({ currentUser, chatId, targetLanguage }) {
  const optionsUnsaveChat = {
    method: "POST",
    body: JSON.stringify({
      userId: currentUser.uid,
      targetLanguage: targetLanguage,
      chatId: chatId,
    }),
    headers: { "Content-Type": "application/json" },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/unsave_chat`,
    optionsUnsaveChat
  ).then((response) => response.json());
}

export async function saveCorrection({
  currentUser,
  correction,
  targetLanguage,
}) {
  const optionsSaveCorrection = {
    method: "POST",
    body: JSON.stringify({
      userId: currentUser.uid,
      targetLanguage: targetLanguage,
      correction: correction,
    }),
    headers: { "Content-Type": "application/json" },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/save_correction`,
    optionsSaveCorrection
  ).then((response) => response.json());
}

export async function addLanguage({ currentUser, language }) {
  const optionsAddLanguage = {
    method: "POST",
    body: JSON.stringify({
      userId: currentUser.uid,
      language: language,
    }),
    headers: { "Content-Type": "application/json" },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/add_language`,
    optionsAddLanguage
  ).then((response) => response.json());
}

export async function saveWord({
  currentUser,
  targetW,
  sourceW,
  sentence,
  translation,
  targetLanguage,
}) {
  const removePunctuation = (word) => {
    const pattern = /^[\p{P}\p{S}]*|[\p{P}\p{S}]*$/gu;
    const replaced = word.replace(pattern, "");
    console.log("Saving", replaced);
    return replaced;
  };

  const optionsSaveWord = {
    method: "POST",
    body: JSON.stringify({
      userId: currentUser.uid,
      targetLanguage: targetLanguage,
      targetW: removePunctuation(targetW),
      sourceW: removePunctuation(sourceW),
      sentence: sentence,
      translation: translation,
    }),
    headers: { "Content-Type": "application/json" },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/save_word`,
    optionsSaveWord
  ).then((response) => response.json());
}

export async function handleStreak({
  currentUser,
  targetLanguage,
  streakData,
  // convoLevel,
}) {
  console.log("🔥 checkifday");
  const checkIfDay = (date) => {
    /* Checks if date corresponds to  yesterday, the day before, or today, etc*/
    // Create a Date object for today
    const today = new Date();
    // Create a Date object for yesterday by subtracting one day (24 hours)
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    // The date we want to check
    const dateToCheck = new Date(date); // Replace with your Date object //'2023-09-29T12:00:00'
    // Compare the dateToCheck with yesterday

    console.log("Comparing", today, date);
    if (
      dateToCheck.getDate() === today.getDate() &&
      dateToCheck.getMonth() === today.getMonth() &&
      dateToCheck.getFullYear() === today.getFullYear()
    ) {
      return "today";
    } else if (
      dateToCheck.getDate() === yesterday.getDate() &&
      dateToCheck.getMonth() === yesterday.getMonth() &&
      dateToCheck.getFullYear() === yesterday.getFullYear()
    ) {
      return "yesterday";
    } else if (dateToCheck < yesterday) {
      return "before yesterday";
    } else {
      return false;
    }
  };

  // This function handles the stuff related to increasing the streak
  // It'll trigger every time the user increases the level of a convo (every time the progress bar reaches the end)
  // A streak consists of:
  // - streak: consecutive days
  // - points (or something like that): every time the bar is completed, it adds 1 point
  // - num of convos - number of convos that reach out the 1st level at least.

  /*
  The params will include: 
    - The level of the convo. If it's level 1:
      - for streaks, it'll see if it's the first convo of the day. If it is, streak += 1
      - for points, it'll add 1
      - for num of convos, it'll add 1

    - For level 2 and 3: 
      - for points, it'll add 1
      - for num of convos, it does nothing.

    ** Maybe add somethign like "crowns" or "trophies" if level 3 is completed? idk. Maybe that'd be too much ** 

  One thing to keep an eye on is that the user can for example save a convo w 9 messages, and then some other day it can open it and 
  add one more message and get the streak. NO. This is not allowed. Cause of this, STREAKS ONLY COUNT FOR CONVOS CREATED THAT SAME DAY.
  I have the convo timestamp so it should be easy.

  Also cause of this, the progress bar will only show if the convo was created that day. Therefore, this logic (the evaluation of whether the 
  convo was created on the same day or not), should be done on the frontend. All the other date comparissons regarding the streak will happen on the backend. 

  Or actually. Each user can have a "date_of_last_convo" property. When retrieving the user data, I can just evaluate against that. It'll make things easier.
  Then, when the user has a streak, I update the date of last convo.

  But actually also, i don't need to do the computation in the backend. If I'll already have the date of the last convo in the frontend, I can just 
  decide here whether the user increased the streak or not. I can do it in this very function.

  Anyways. Steps involved. 
    - When a convo reaches a new level, use a mutation. 
    - When loading user data, get data of last convo (if it doesn't exist, have a default.)

  This function will get the language of the chat, the date of the last convo, and the level of the convo.

  */

  // Calculate isStreak by comparing the dateOfLastConvo against the chat's timestamp.
  //

  // Date object (today)
  const todaysDate = new Date();

  // let currentDay = String(date.getDate()).padStart(2, "0");
  // let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
  // let currentYear = date.getFullYear();
  // let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
  // let date2 = new Date(currentDate);

  console.log("STREAK LAST CHAT: ", streakData.dateLastChat);
  console.log("IS: ", checkIfDay(streakData.dateLastChat));

  /*
  If this function is triggered, means that today's date instantly becomes the new date of last chat
  
  As for the streak:
  if there's no date, streak = 1
  if the date is less than yesterday, the streak is 1
  if the date is yesterday, show streak increase streak by 1
  if the date is today, show streak streak stays the same
  */

  if (!streakData?.totalChats) {
    streakData.totalChats = 0;
  }
  let newStreak;
  if (!streakData.dateLastChat) {
    console.log("This is the first conversation in the language");
    newStreak = 1;
  } else if (checkIfDay(streakData.dateLastChat) == "before yesterday") {
    newStreak = 1;
  } else if (checkIfDay(streakData.dateLastChat) == "yesterday") {
    newStreak = streakData.streak + 1;
  } else if (checkIfDay(streakData.dateLastChat) == "today") {
    newStreak = streakData.streak;
  }
  const newStreakData = {
    dateLastChat: todaysDate,
    streak: newStreak,
    totalChats: streakData.totalChats + 1,
  };
  const optionsHandleStreak = {
    method: "POST",
    body: JSON.stringify({
      userId: currentUser.uid,
      targetLanguage: targetLanguage,
      streakData: newStreakData,
    }),
    headers: { "Content-Type": "application/json" },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/handle_streak`,
    optionsHandleStreak
  ).then((response) => response.json());
}

// GET REQUESTS
export async function getAudio() {
  // Not used i think
  const optionsGetAudio = {
    method: "POST",
    headers: {
      accept: "audio/mpeg",
      "xi-api-key": process.env.REACT_APP_ELEVEN_LABS_API_KEY,
      "Content-Type": "application/json",
    },
    data: {
      text: "hello",
      model_id: "eleven_monolingual_v1",
      voice_settings: {
        stability: 0.5,
        similarity_boost: 0.5,
      },
    },
  };

  return fetch(
    "https://api.elevenlabs.io/v1/text-to-speech/AZnzlk1XvdvUeBnXmlld",
    optionsGetAudio
  ).then((response) => console.log(response.json()));
}

export async function getVoices() {
  const optionsGetVoices = {
    accept: "application/json",
    "xi-api-key": process.env.REACT_APP_ELEVEN_LABS_API_KEY,
  };
  return fetch("https://api.elevenlabs.io/v1/voices", optionsGetVoices);
}

export function useGetVoicesQuery() {
  return useQuery({
    queryKey: ["voices"],
    queryFn: () => getVoices(),
    staleTime: 60000,
  });
}

export const startStreamingNarakeet = async (text) => {
  const apikey = "sdXlskdk1P8HJdW9NrjAs61OhmfqsQDO74hBVqnt";
  const url = `https://api.narakeet.com/text-to-speech/m4a?voice=luigi`;
  try {
    // Create a TextEncoder object
    const textEncoder = new TextEncoder();
    const utf8Encoded = textEncoder.encode(text);
    const ttsOptions = {
      method: "POST",
      headers: {
        Accept: "application/octet-stream",
        "Content-Type": "application/json",
        "x-api-key": apikey,
        mode: "cors",
        "Access-Control-Allow-Origin": "*",
      },
      body: utf8Encoded,
    };
    fetch(url, ttsOptions)
      .then((response) => {
        const audio = new Audio(response.data.url);
        audio.play();
      })
      .catch((err) => console.log("oopsies error", err));
  } catch (error) {
    console.error(error);
  }
};

export const startStreaming = async (text) => {
  const voiceId = "IKne3meq5aSn9XLyUdCD"; //"21m00Tcm4TlvDq8ikWAM";
  const apiKey = process.env.REACT_APP_ELEVEN_LABS_API_KEY;
  const voiceSettings = {
    stability: 1,
    similarity_boost: 0.5,
    speaker_boost: false,
  };
  const baseUrl = "https://api.elevenlabs.io/v1/text-to-speech";
  const headers = {
    "Content-Type": "application/json",
    "xi-api-key": apiKey,
  };
  const requestBody = {
    text,
    voice_settings: voiceSettings,
    model_id: "eleven_multilingual_v2",
  };

  try {
    const response = await axios.post(`${baseUrl}/${voiceId}`, requestBody, {
      headers,
      responseType: "blob",
    });

    if (response.status === 200) {
      const audio = new Audio(URL.createObjectURL(response.data));
      audio.play();
    } else {
      // setError("Error: Unable to stream audio.");
      console.log("Error: Unable to stream audio. Code:", response.status);
    }
  } catch (error) {
    // setError("Error: Unable to stream audio.");
    console.log("Error: Unable to stream audio.", error);
  }
};

async function getSavedChats(userId, targetLanguage) {
  return fetch(
    process.env.REACT_APP_API_URL +
      "/get_saved_chats?" +
      new URLSearchParams({
        user_id: userId,
        target_language: targetLanguage,
      })
  ).then((response) => response.json());
}

export function useSavedChatsQuery({
  currentUser,
  targetLanguage,
  select,
  enabled,
}) {
  return useQuery({
    queryKey: ["savedChats"],
    queryFn: () => getSavedChats(currentUser.uid, targetLanguage),
    enabled: enabled,
    select: select
      ? select
      : (data) => {
          return data;
        },
    staleTime: 60000,
  });
}

async function getWordByWordTranslation(message, targetLanguage) {
  return fetch(
    process.env.REACT_APP_API_URL +
      "/get_word_translations?" +
      new URLSearchParams({
        message: message,
        targetLanguage: targetLanguage,
      })
  ).then((response) => response.json());
}

export function useWordByWordTranslationQuery({
  message,
  targetLanguage,
  select,
  enabled,
}) {
  return useQuery({
    queryKey: ["wordTranslations"],
    queryFn: () => getWordByWordTranslation(message, targetLanguage),
    enabled: enabled,
    select: select
      ? select
      : (data) => {
          return data;
        },
    staleTime: 60000,
  });
}

// -----------------------------------------
// -----------------------------------------
export async function translateMessage({
  messageContent,
  sourceLanguage,
  targetLanguage,
}) {
  const optionsTranslateMessage = {
    method: "POST",
    body: JSON.stringify({
      messageContent: messageContent,
      sourceLanguage: sourceLanguage,
      targetLanguage: targetLanguage,
    }),
    headers: { "Content-Type": "application/json" },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/translate`,
    optionsTranslateMessage
  ).then((response) => response.json());
}
