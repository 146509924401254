import React, { useState, useContext, useRef, useEffect } from "react";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { Link, Navigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { useAuth } from "../context/AuthContext";
import { TbArrowBigLeft } from "react-icons/tb";
import "./Vocab.css";
import { Scrollbars } from "react-custom-scrollbars-2";
import Navbar from "../Navbar";

function Vocab() {
  const [activeVocabWord, setActiveVocabWord] = useState("");
  const [currentVocabCard, setCurrentVocabCard] = useState(null);
  const [vocabData, setVocabData] = useState([]);
  const [sidebarActive, setSidebarActive] = useState(false);
  const [toggleSort, setToggleSort] = useState(false);
  const { dispatch, currentTargetLanguage } = useContext(AppContext);
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();
  const scrollbarRef = useRef();

  const commonWordMap = [
    "rare",
    "not common",
    "common",
    "very common",
    "very common",
  ];

  async function getSavedWords() {
    console.log("Retrieving", currentTargetLanguage);
    return fetch(
      process.env.REACT_APP_API_URL +
        "/get_saved_words?" +
        new URLSearchParams({
          userId: currentUser.uid,
          targetLanguage: currentTargetLanguage,
        })
    ).then((response) => response.json());
  }

  const { data, isLoading } = useQuery({
    queryKey: ["savedWords", currentTargetLanguage],
    queryFn: () => getSavedWords(),
    staleTime: Infinity,
    retry: 3,
    // enabled: tempVersaMessage.content ? true : false,
    onSuccess: (data) => {
      console.log("Dataaa", data);
      setVocabData(data);
    },
  });

  const highlighter = (sentence, highlightWord) => {
    if (!sentence || !highlightWord) {
      return;
    }
    let newWord = highlightWord;
    if (highlightWord.endsWith("?")) {
      newWord = highlightWord.slice(0, -1) + "\\?";
    }
    const parts = sentence.split(new RegExp(`(${newWord})`, "gi"));
    console.log(highlightWord, parts);
    return parts.map((part, index) => (
      <span
        key={index}
        className={`${part === highlightWord ? "highlighted-word px-1" : ""}`}
      >
        {part}
      </span>
    ));
  };

  /* <div
      key={index}
      className={`word-card  my-1 ${
        activeVocabWord == index
          ? "active my-3 p-4"
          : "inactive px-3 py-2 mx-3"
      }`}
      // data-bs-toggle="collapse"
      // data-bs-target={`#vocab-info-${index}`}
      // aria-expanded="false"
      // aria-controls="collapseExample"
      onClick={() => setActiveVocabWord(index)}
    >
      <div className="row">
        <strong className="col-1">{targetW}</strong>
        <div className="col-1 text-muted">{sourceW}</div>
        <div className="col">{highlighter(sentence, targetW)}</div>
        <span className="col-auto dropdown-toggle me-2 dropdow-toggle-caret" />
      </div>
      <div
        id={`vocab-info-${index}`}
        className={`collapse ${
          activeVocabWord == index ? "show" : ""
        }`}
      >
        <div className="vocab-word-info-container p-4">
          {translation}
        </div>
      </div>
    </div> */

  useEffect(() => {
    if (data) {
      setVocabData(data);
    }
  }, []);

  const sortVocab = () => {
    if (toggleSort) {
      setVocabData(data);
    } else {
      const vocab = {
        noun: [],
        verb: [],
        adjective: [],
        adverb: [],
        preposition: [],
        pronoun: [],
        interjection: [],
        particle: [],
        other: [],
      };
      for (var wordObject of vocabData) {
        if (vocab[wordObject["part_of_speech"]]) {
          vocab[wordObject["part_of_speech"]].push(wordObject);
        } else {
          vocab.other.push(wordObject);
        }
      }

      const sortedData = [
        ...vocab.noun,
        ...vocab.verb,
        ...vocab.adjective,
        ...vocab.adverb,
        ...vocab.preposition,
        ...vocab.pronoun,
        ...vocab.interjection,
        ...vocab.particle,
        ...vocab.other,
      ];
      setVocabData(sortedData);
      console.log(vocabData);
    }
    setToggleSort(!toggleSort);
  };

  return !currentUser ? (
    <Navigate to="/signup" replace={true} />
  ) : !currentTargetLanguage ? (
    <Navigate to="/dashboard" replace={true} />
  ) : (
    <div>
      <Navbar />
      <div className="d-flex h-100">
        <div
          className={`d-none d-md-flex flex-column sidebar pt-4 ${
            sidebarActive ? "active px-3" : "px-2"
          }`}
        >
          <Link to="/dashboard">
            <div id="go-back-btn" className="py-2 px-3 text-center">
              <TbArrowBigLeft size="24px" />
            </div>
          </Link>
        </div>
        <div className="justify-content-center p-2 p-md-4 w-100">
          <div className="main-page-container container h-100">
            <h2 className="mt-3 mt-sm-0">Vocabulary</h2>
            Review the words that you've saved from your chats, with details and
            examples for each one.
            {isLoading ? (
              <div className="w-100 mh-100 d-flex flex-column justify-content-center align-items-center pt-5 mt-5">
                <div className="spinner-border" />
              </div>
            ) : data && data.length == 0 ? (
              <div className="w-100 mh-100 d-flex bg-light flex-column justify-content-center align-items-center py-5 mt-5 no-vocab-message">
                <h4>
                  You haven't saved any {currentTargetLanguage} words yet{" "}
                </h4>
                <div>
                  Once you start saving words from the chat, you'll be able to
                  see them here!
                </div>
              </div>
            ) : (
              <div className="vocab-container pt-4 row">
                <div className="col-12 col-md row pb-5 pb-sm-3 h-100 w-100 d-flex align-items-start justify-content-start">
                  <div className="d-flex pb-3">
                    <h4 className="px-3">
                      {vocabData.length}
                      {" words"}
                    </h4>

                    <button
                      className={`btn btn-light btn-sm sort-words-btn px-3 ${
                        toggleSort ? "active" : "inactive"
                      }`}
                      onClick={sortVocab}
                    >
                      sort
                    </button>
                  </div>
                  {vocabData.map((vocabWord, index) => (
                    <>
                      <div
                        key={index}
                        className="p-1 col-6 col-md-4 d-lg-none"
                        id="vocab-details-btn"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#vocab-details-modal"
                      >
                        <div
                          onClick={() => {
                            if (
                              currentVocabCard?.targetW == vocabWord.targetW
                            ) {
                              setCurrentVocabCard(null);
                            } else {
                              setCurrentVocabCard(vocabWord);
                            }
                          }}
                        >
                          <div
                            className={`word-card py-2 px-3 ${
                              currentVocabCard?.targetW == vocabWord.targetW
                                ? "active"
                                : "inactive"
                            }`}
                          >
                            <div className="d-flex justify-content-between">
                              <div>
                                <strong className="">
                                  {vocabWord.targetW}
                                </strong>
                              </div>
                              <small>
                                <div
                                  className={`details-pos details-pos-${vocabWord?.part_of_speech.toLowerCase()} col-auto px-2`}
                                >
                                  {vocabWord?.part_of_speech}
                                </div>
                              </small>
                            </div>
                            <div className="text-muted">
                              {vocabWord.sourceW}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        key={index}
                        className={`d-none d-lg-block p-1  ${
                          currentVocabCard
                            ? "col-md-6 col-lg-4"
                            : "col-md-4 col-lg-3"
                        } `}
                        onClick={() => {
                          if (currentVocabCard?.targetW == vocabWord.targetW) {
                            setCurrentVocabCard(null);
                          } else {
                            setCurrentVocabCard(vocabWord);
                          }
                        }}
                      >
                        <div
                          className={`word-card py-2 px-3 ${
                            currentVocabCard?.targetW == vocabWord.targetW
                              ? "active"
                              : "inactive"
                          }`}
                        >
                          <div className="d-flex justify-content-between">
                            <div>
                              <strong className="">{vocabWord.targetW}</strong>
                            </div>
                            <small>
                              <div
                                className={`details-pos details-pos-${vocabWord?.part_of_speech.toLowerCase()} col-auto px-2`}
                              >
                                {vocabWord?.part_of_speech}
                              </div>
                            </small>
                          </div>
                          <div className="text-muted">{vocabWord.sourceW}</div>
                        </div>
                      </div>
                    </>
                  ))}
                  <div
                    id="vocab-details-modal"
                    className="modal fade"
                    tabIndex="-1"
                    aria-labelledby="vocab-details-modal"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content p-4 text-center">
                        <div className="modal-body">
                          <h1 className="text-center">
                            {currentVocabCard?.targetW}
                          </h1>
                          {currentVocabCard?.phonetic && (
                            <div className="lead text-center">
                              {currentVocabCard?.phonetic}
                            </div>
                          )}
                          <p className="lead text-center">
                            {currentVocabCard?.sourceW}
                          </p>
                          <div className="row d-flex justify-content-center">
                            <div
                              className={`details-pos details-pos-${currentVocabCard?.part_of_speech.toLowerCase()} col-auto px-2 mx-1`}
                            >
                              <small>{currentVocabCard?.part_of_speech}</small>
                            </div>
                            <div className="details-common col-auto px-2 mx-1">
                              <small>
                                {commonWordMap[currentVocabCard?.common - 1]}
                              </small>
                            </div>
                            <div className="details pt-3">
                              <small>
                                <div id="vocab-examples-toggle">
                                  <div
                                    className={`btn btn-sm  w-100 px-2 d-flex`}
                                    data-bs-toggle="collapse"
                                    data-bs-target="#vocab-details-container"
                                    aria-expanded="false"
                                    aria-controls="collapseExample"
                                    //onClick={() => setSuggestionsActive(!suggestionsActive)}
                                  >
                                    <div
                                      // className={`dropdown-toggle me-2 dropdow-toggle-caret ${
                                      //   suggestionsActive
                                      //     ? "dropdown-toggle-caret-active"
                                      //     : "dropdown-toggle-caret-closed"
                                      // }`}
                                      className="dropdown-toggle dropdow-toggle-caret me-2"
                                    />
                                    <strong className="">More</strong>
                                  </div>
                                  <div
                                    id="vocab-details-container"
                                    className="collapse p-3"
                                  >
                                    {currentVocabCard?.base && (
                                      <>
                                        <div className="details-base-word">
                                          <strong className="">
                                            Base word
                                          </strong>
                                          <div className="px-2">
                                            {currentVocabCard?.base}
                                          </div>
                                        </div>
                                        <br />
                                      </>
                                    )}
                                    <div>
                                      <strong>Examples</strong>

                                      <div className="px-2">
                                        {currentVocabCard?.examples.map(
                                          ({ ex, tr }, index) => (
                                            <div key={index} className="py-1">
                                              <div>{ex}</div>
                                              <div className="text-muted">
                                                {tr}
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                    <br />
                                    <div>
                                      <strong>Original sentence</strong>
                                      <div className="px-2 pt-1">
                                        {highlighter(
                                          currentVocabCard?.sentence,
                                          currentVocabCard?.targetW
                                        )}

                                        <div>
                                          <div className="text-muted">
                                            {currentVocabCard?.translation}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-auto d-none d-lg-block">
                  <div
                    className={`current-vocab-card-container col-auto ${
                      currentVocabCard ? "active" : "inactive"
                    }`}
                  >
                    {currentVocabCard && (
                      <div className="vocab-card-wrapper px-4 py-3">
                        <div className="current-vocab-card px-4 py-5">
                          <h1 className="text-center">
                            {currentVocabCard.targetW}
                          </h1>
                          {currentVocabCard?.phonetic && (
                            <div className="lead text-center">
                              {currentVocabCard.phonetic}
                            </div>
                          )}
                          <p className="lead text-center">
                            {currentVocabCard.sourceW}
                          </p>
                          <div className="row d-flex justify-content-center">
                            <div
                              className={`details-pos details-pos-${currentVocabCard?.part_of_speech.toLowerCase()} col-auto px-2 mx-1`}
                            >
                              <small>{currentVocabCard?.part_of_speech}</small>
                            </div>
                            <div className="details-common col-auto px-2 mx-1">
                              <small>
                                {commonWordMap[currentVocabCard?.common - 1]}
                              </small>
                            </div>
                          </div>

                          <div className="details pt-3">
                            <small>
                              <div id="vocab-examples-toggle">
                                <div
                                  className={`btn btn-sm  w-100 text-start px-2 d-flex`}
                                  data-bs-toggle="collapse"
                                  data-bs-target="#vocab-details-container"
                                  aria-expanded="false"
                                  aria-controls="collapseExample"
                                  //onClick={() => setSuggestionsActive(!suggestionsActive)}
                                >
                                  <div
                                    // className={`dropdown-toggle me-2 dropdow-toggle-caret ${
                                    //   suggestionsActive
                                    //     ? "dropdown-toggle-caret-active"
                                    //     : "dropdown-toggle-caret-closed"
                                    // }`}
                                    className="dropdown-toggle dropdow-toggle-caret me-2"
                                  />
                                  <strong>More</strong>
                                </div>
                                <div
                                  id="vocab-details-container"
                                  className="collapse p-3"
                                >
                                  {currentVocabCard.base && (
                                    <>
                                      <div className="details-base-word">
                                        <strong className="">Base word</strong>
                                        <div className="px-2">
                                          {currentVocabCard?.base}
                                        </div>
                                      </div>
                                      <br />
                                    </>
                                  )}
                                  <div>
                                    <strong>Examples</strong>

                                    <div className="px-2">
                                      {currentVocabCard?.examples.map(
                                        ({ ex, tr }, index) => (
                                          <div key={index} className="py-1">
                                            <div>{ex}</div>
                                            <div className="text-muted">
                                              {tr}
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                  <br />
                                  <div>
                                    <strong>Original sentence</strong>
                                    <div className="px-2 pt-1">
                                      {highlighter(
                                        currentVocabCard.sentence,
                                        currentVocabCard.targetW
                                      )}

                                      <div>
                                        <div className="text-muted">
                                          {currentVocabCard.translation}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </small>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Vocab;
