import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Error404Page.css";

function Error404Page() {
  const [hoveringOnButton, setHoveringOnButton] = useState(false);
  return (
    <div>
      <div className="container-fluid">
        <div className="d-flex flex-column vh-100 align-items-center justify-content-center">
          <h1 id="heading-404">{!hoveringOnButton ? "404 :(" : "404 :)"}</h1>
          <div className="lead">Looks like you're lost.</div>
          <div className="lead">
            Dw though, it happens to all of us at some point.
          </div>
          <Link to="/">
            <button
              id="go-home-btn"
              onMouseEnter={() => setHoveringOnButton(true)}
              onMouseLeave={() => setHoveringOnButton(false)}
              onM
              className="btn btn-light btn-lg mt-3"
            >
              <strong>Let's go home</strong>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Error404Page;
