import React from "react";
import "./StreakProgressBar.css";

function StreakProgressBar({ messagesCount }) {
  // <div className="d-flex">
  //     <div className="progress-bar-container w-100 mt-1">
  //     <div
  //         className={`progress-bar text-end px-4`}
  //         style={{
  //         width: `${100}%`,
  //         }}
  //     >
  //         <strong> 0</strong>
  //     </div>
  //     <div
  //         className={`progress-bar level-1 text-end px-4`}
  //         style={{
  //         width: `${
  //             (messagesCount > 0) & (messagesCount <= 10)
  //             ? messagesCount * 10
  //             : 100
  //         }%`,
  //         }}
  //     >
  //         <strong>1</strong>
  //     </div>
  //     <div
  //         className={`progress-bar level-2 text-end px-4 ${
  //         messagesCount < 11 ? "d-none" : ""
  //         } `}
  //         style={{
  //         width: `${messagesCount >= 20 ? 100 : (messagesCount - 10) * 10}%`,
  //         }}
  //     >
  //         <strong>2</strong>
  //     </div>
  //     <div
  //         className={`progress-bar level-3 text-end px-4 ${
  //         messagesCount < 21 ? "d-none" : ""
  //         }`}
  //         style={{
  //         width: `${messagesCount >= 30 ? 100 : (messagesCount - 20) * 10}%`,
  //         }}
  //     >
  //         <strong>3</strong>
  //     </div>
  //     </div>
  // </div>
  const completionPercentage = (messagesCount / 7) * 100;
  // console.log("COUNT ", messagesCount, completionPercentage);
  return (
    <div className="progress mt-1">
      <div
        className="progress-bar"
        role="progressbar"
        style={{ width: `${completionPercentage}%` }}
        // aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  );
}

export default StreakProgressBar;
