import React from "react";
import { Link, Navigate } from "react-router-dom";
import "./LandingPage.css";
import Navbar from "../Navbar";
import { useAuth } from "../context/AuthContext";
import YoutubeEmbed from "../YoutubeEmbed";
import logo from "../images/logo-cropped.png";
import testimonials from "../images/testimonials.jpg";
import vocab_sc from "../images/vocab-sc.png";
import sc1 from "../images/sc1.png";
import russian_convo from "../images/versa_russian_convo_sc.png";

function LandingPage() {
  const supportedLanguages = [
    "Spanish",
    "Italian",
    "Portuguese",
    "French",
    "German",
    "Luxembourgish",
    "Hungarian",
    "Polish",
    "Hindi",
    "Swahili",
    "Chinese",
    "Japanese",
    "Korean",
    "Russian",
  ];
  const { currentUser } = useAuth();
  return currentUser ? (
    <Navigate to="/dashboard" replace={true} />
  ) : (
    <>
      <Navbar type="landing" />
      <div className="container-fluid p-0 ">
        <div className="row lp-section main">
          <div className="col-12 col-md-5 landing-img"></div>
          <div className="col-12 col-md-7 d-flex flex-column justify-content-center align-items-start px-4 px-md-5 vh-100">
            <div className="w-100">
              <img className="logo-landing mb-4" src={logo} alt="logo" />
              <h1
                id="landing-main-title"
                className="text-start mt-1 d-none d-sm-block"
              >
                Daily natural conversations <br /> with your AI language tutor
              </h1>
              <h1
                id="landing-main-title-mobile"
                className="text-start mt-1 d-sm-none"
              >
                Daily natural conversations <br /> with your AI language tutor
              </h1>
            </div>
            <p className="subtitle text-start pt-3">
              Improve your vocabulary, get instant feedback, and immerse
              yourself in the language by having short and engaging
              conversations about a variety of topics.
              {/*Learn languages by having natural, real, and open-ended
            conversations with your very own AI language tutor.*/}
            </p>
            <Link to="/dashboard">
              <button className="btn btn-lg cta-button animated mt-4 px-4">
                Let's begin
              </button>
            </Link>
          </div>
          <div id="lp-main-transition" className="w-100"></div>
        </div>
        <div className="lp-section second-section d-flex flex-column align-items-center justify-content-center">
          <div className="text-center px-3">
            {/* <div className="lead">Let's take you to that</div>
            <h2>
              <div>first fluent conversation</div>
              <div>quicker. </div>
            </h2> */}
            <div className="lead">Our goal?</div>
            <h2>Taking you to that first fluent conversation</h2>
            <div>
              We're about helping you get to that first fluent conversation in
              your target language.
            </div>
          </div>
        </div>
        <div className="lp-section demo-video py-5">
          <div id="second-transition" className="w-100"></div>
          <center>
            <div className="p-4 video-container mb-4 mt-5">
              <YoutubeEmbed embedId="X3ex_Fj3nd4" />
            </div>
          </center>
        </div>
        <div className="lp-section features-section p-md-5 p-xl-3">
          <div className="row d-flex justify-content-center align-items-start px-xl-4 pt-xl-5">
            <div className="col-12 col-xl-7 p-4">
              <div className="versa-screenshot-container d-flex flex-column justify-content-center align-items-center p-5 ">
                <div className="w-100 pb-2 px-2 text-center">
                  <h3>Practice real conversations</h3>
                  <p>
                    Have short daily conversations about a variety of topics.
                  </p>
                </div>
                <img
                  // id="versa-avengers-convo"
                  className="versa-screenshot"
                  src={russian_convo}
                  alt="versa screenshot"
                />
              </div>
            </div>
            <div className="col-12 col-xl-3 d-none d-sm-flex flex-column align-items-start justify-content-start h-100 p-4">
              {/* <div className="versa-screenshot-container p-5 ">
              <div className="w-100 pb-2 px-2 text-center">
                <h4>Practice your vocabulary</h4>
                <p>Save words from the chats, and review them later.</p>
              </div>
              <img
                // id="versa-avengers-convo"
                className="versa-screenshot"
                src={vocab_sc}
                alt="versa screenshot"
              />
            </div> */}
              {/* <div className="p-4 border mb-2 w-100 versa-screenshot-container">
                <h4>Short daily conversations</h4>
                <div>One quick chat a day to practice your skills.</div>
              </div> */}
              <div className="p-4 border mb-2 w-100 versa-screenshot-container">
                <h4>Get detailed corrections</h4>
                <div>Instant feedback and corrections on your mistakes.</div>
              </div>
              <div className="p-4 border mb-2 w-100 versa-screenshot-container">
                <h4>Talk about different topics</h4>
                <div>
                  Roleplay at a caffe, talk about your day, or about the last
                  movie you saw.
                </div>
              </div>
              <div className="p-4 border mb-2 w-100 versa-screenshot-container">
                <h4>Ask questions</h4>
                <div>
                  Stuck? Use AskVersa to get in depth help about grammar,
                  vocabulary, and those tricky aspects of the language.
                </div>
              </div>
            </div>{" "}
          </div>
          <div className="row d-flex justify-content-center align-items-start px-xl-4 pb-5">
            <div className="col-12 col-lg-6 col-xl-5 p-4">
              <div className="versa-screenshot-container p-5 ">
                <div className="w-100 pb-2 px-2 text-center">
                  <h4>Go in depth</h4>
                  <p>
                    Learn new words, expressions, and hear the pronunciations.
                  </p>
                </div>
                <img
                  // id="versa-avengers-convo"
                  className="versa-screenshot"
                  src={sc1}
                  alt="versa screenshot"
                />
              </div>
            </div>{" "}
            <div className="col-12 col-lg-6 col-xl-5 p-4">
              <div className="versa-screenshot-container p-5 ">
                <div className="w-100 pb-2 px-2 text-center">
                  <h4>Practice your vocabulary</h4>
                  <p>Save words from the chats, and review them later.</p>
                </div>
                <img
                  // id="versa-avengers-convo"
                  className="versa-screenshot"
                  src={vocab_sc}
                  alt="versa screenshot"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="lp-section pick-your-language-section d-flex flex-column align-items-center justify-content-center py-5 text-center">
          <div className="text-center p-3 mt-5">
            <div className="lead">The journey starts today</div>
            <h2 className="py-2">I would like to have a conversation in...</h2>
          </div>
          <div className="row d-flex mb-5 align-items-center justify-content-center supported-langs-container px-1">
            {supportedLanguages.map((lang, index) => (
              <div
                key={index}
                className="col-auto text-center my-2 mx-2 py-2 px-4 supported-lang"
              >
                {lang}
              </div>
            ))}
          </div>
        </div>

        {/* <div className="lp-section row p-5 d-flex justify-content-center">
          <div className="col-6">
            <div className="versa-screenshot-container p-5">
              <img
                className="versa-screenshot"
                src={dashboard_sc}
                alt="versa screenshot"
              />
            </div>
          </div>
          <div className="col-auto">
            <div className="versa-screenshot-container p-4">
              <div className="w-100 pt-2 px-2 text-center">
                <h4>Get precise corrections</h4>
              </div>
              <img
                id="versa-avengers-convo"
                className="versa-screenshot"
                src={sc1}
                alt="versa screenshot"
              />
            </div>
          </div>
          <div className="col-auto">
            <div className="versa-screenshot-container p-4">
              <div className="w-100 pt-2 px-2 text-center">
                <h4>Get precise corrections</h4>
              </div>
              <img
                id="versa-avengers-convo"
                className="versa-screenshot"
                src={sc2}
                alt="versa screenshot"
              />
            </div>
          </div>
          <div className="col-auto">
            <div className="versa-screenshot-container p-4">
              <div className="w-100 pt-2 px-2 text-center">
                <h4>Practice your vocabulary</h4>
              </div>
              <img
                id="versa-avengers-convo"
                className="versa-screenshot"
                src={vocab_sc}
                alt="versa screenshot"
              />
            </div>
          </div>

          <div className="col-3">
            <div className="versa-screenshot-container p-4">
              <img
                className="versa-screenshot"
                src={ask_versa_sc}
                alt="versa screenshot"
              />
            </div>
          </div>
          <div className="col-3">
            <div className="versa-screenshot-container p-4">
              <img
                className="versa-screenshot"
                src={vocab_detail_sc}
                alt="versa screenshot"
              />
            </div>
          </div>
        </div> */}

        {/* <div className="lp-section info d-flex flex-column justify-content-center py-lg-5 my-0 my-lg-5 px-3">
          <div className="row py-sm-5 my-1 px-0 px-sm-3 px-lg-5">
            <div className="col-12 col-lg-6 d-flex justify-content-end align-items-end">
              <div className="versa-screenshot-container p-4">
                <img
                  className="versa-screenshot"
                  src={versa_sc}
                  alt="versa screenshot"
                />
              </div>
            </div>

            <div className="col-12 col-lg-6 px-lg-5 text-center text-lg-start py-0">
              <div className="mt-5 mt-lg-0 mx-lg-4 landing-paragraph">
                <h3>Because the real world doesn't follow a script</h3>
                <p>
                  Learn a language by having entertaining and open-ended
                  conversations about a variety of topics, while getting the
                  corrections, guidance, and feedback from your very own AI
                  language tutor.
                </p>

                <div>
                  <strong>No</strong> categories
                </div>
                <div>
                  <strong>No</strong> pre-scripted sentences
                </div>
                <p>
                  <strong>No</strong> memorizing words you'll never use
                </p>
                <div>Say what's in your mind, Versa does the rest.</div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="lp-section features d-none">
          <div className="row h-100 ps-lg-5 py-2 py-sm-5">
            <div className="px-5 mt-3 pt-3 mb-5 pb-5">
              <h1 className="text-center pb-4 mb-4">
                Join Versa if you want to...
              </h1>
              <div className="row px-sm-5">
                <div className="col-12 col-sm-6 col-lg-3 p-0">
                  <div className="m-2 p-3 features-card">
                    <strong>Practice in different situations</strong>
                    <div className="pt-1">
                      Practice in different contexts, such as a coffee shop, or
                      the gym.
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-6 col-lg-3 p-0">
                  <div className="m-2 p-3 features-card">
                    <strong>Have open-ended conversations.</strong>
                    <div className="pt-1">
                      Try different types of messages and find out what else
                      versa can do.
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3 p-0">
                  <div className="m-2 p-3 features-card">
                    <strong>Get suggested replies</strong>
                    <div className="pt-1">
                      Get useful suggestions to keep the conversation going.
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3 p-0">
                  <div className="m-2 p-3 features-card">
                    <strong>Get detailed corrections </strong>
                    <div className="pt-1">
                      Let versa correct your errors and give you detailed
                      explanations.
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3 p-0">
                  <div className="m-2 p-3 features-card">
                    <strong>
                      Word by word translations{" "}
                      <small className="text-muted">[🥳 new]</small>
                    </strong>
                    <div className="py-1">
                      Get translations and examples for individual words and
                      phrases.
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3 p-0">
                  <div className="m-2 p-3 features-card features-card-registered">
                    <strong>
                      Save your conversations{" "}
                      <small className="text-muted">[🥳 new]</small>
                    </strong>
                    <div className="py-1">
                      Keep track of all your chats so you can review them at a
                      later time.
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3 p-0">
                  <div className="m-2 p-3 features-card features-card-registered">
                    <strong>Save words and phrases</strong>
                    <div className="py-1">
                      Save the words and phrases you find useful, for future
                      practice.
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3 p-0">
                  <div className="m-2 p-3 features-card features-card-registered">
                    <strong>Voice to voice conversations</strong>
                    <div className="py-1">
                      Practice your pronunciation and listening skills by
                      talking to versa!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="lp-section testimonials  py-5  px-3 how-to-use d-flex flex-column justify-content-center align-items-center">
          <div className="row">
            <div className="col-12">
              <div className="versa-screenshot-container p-5 my-5">
                <h2 className="text-center pb-2">Hear it from our users</h2>
                <div className="my-1 px-2 px-md-5 d-flex justify-content-around align-items-start w-100">
                  <img
                    className="testimonials-image"
                    src={testimonials}
                    alt="twitter testimonials"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="lp-section testimonials-mobile d-none py-5 px-3 how-to-use d-flex flex-column justify-content-center align-items-center">
          <div className="row">
            <div className="col-12">
              <div className="p-5 my-3 ">
                <h2 className="text-center pb-2">Hear it from our users</h2>
                <div className="my-1 px-2 px-md-5 d-flex justify-content-around align-items-start w-100">
                  <img
                    className="testimonials-image"
                    src={testimonials}
                    alt="twitter testimonials"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="lp-section banner px-3 d-flex flex-column justify-content-center align-items-center">
          <h1>Ready to start learning?</h1>
          <p className="pt-3 subtitle text-light">
            Join the new way of learning languages
          </p>
          <Link to="/dashboard">
            <button className="btn cta-button btn-lg mt-4 px-4">
              Let's begin
            </button>
          </Link>
        </div>

        <div className="footer d-flex flex-row-reverse py-3 px-4 w-100">
          <div>© 2023</div>
        </div>
      </div>
    </>
  );
}

export default LandingPage;
