import React from "react";

function Plans() {
  return (
    <div className="container">
      <div className="p-5">
        <h1>This is the plans page</h1>
        <div>
          <a
            className="p-0"
            href="https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-1NV55779SH191880CMS3P63Q"
            target="_blank"
          >
            <button className="btn mt-2 ps-3 pe-4 subscribe-btn">
              <span className="subscribe-btn-text">⭐ Subscribe</span>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Plans;
