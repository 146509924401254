import React, { useContext } from "react";
import { useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "./context/AuthContext";
import logo from "./images/logo.png";
import { AppContext } from "./context/AppContext";
import { TbBrandTwitter } from "react-icons/tb";
import SubscribeModal from "./SubscribeModal";

const Navbar = ({ type }) => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { dispatch } = useContext(AppContext);

  const handleLogout = () => {
    logout()
      .then(() => {
        console.log("LOGING OUT");
        // This one should work. If not, look into other methods of queryClient.
        queryClient.removeQueries();
        dispatch({
          type: "CLEAR_CHAT",
          payload: {},
        });
        navigate("/");
      })
      .catch((err) => {
        console.log("HEREEEE", err);
      });
  };
  /*

          */
  return (
    <nav className={`navbar navbar-expand-sm ${type} px-sm-2 py-2 w-100`}>
      <div className="container-fluid px-sm-1">
        <div className="flex-col ms-0 ms-sm-1 me-auto">
          <a className="p-1" href="#">
            <img className="" src={logo} alt="logo" />
          </a>
        </div>
        <div>
          <ul className="navbar-nav mb-1 mb-lg-0 px-sm-3 d-flex align-items-center">
            {/* 
            <a
              className="p-0"
              href="https://twitter.com/_pabloomvc"
              target="_blank"
            >
              <button className="btn btn-light d-none d-sm-block icon-container">
                <li className="nav-item">
                  <TbBrandTwitter className="" size="24px" />
                </li>
              </button>
            </a>
            */}
            {
              // SUPPORT THE PROJECT
              // currentUser && (
              //   /*
              //   <a
              //     className="p-0"
              //     href="https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-1NV55779SH191880CMS3P63Q"
              //     target="_blank"
              //   >
              //     <button className="btn ps-3 pe-4 subscribe-btn">
              //       <span className="subscribe-btn-text">
              //         <strong>⭐ Go pro</strong>
              //       </span>
              //     </button>
              //   </a>
              // */
              //   <>
              //     <button
              //       className="btn ms-2 subscribe-btn"
              //       type="button"
              //       data-bs-toggle="modal"
              //       data-bs-target="#subscribe-modal"
              //     >
              //       ⭐ Support the project
              //     </button>
              //     <SubscribeModal />
              //   </>
              // )
            }
            {currentUser ? (
              <li className="nav-item ms-sm-3">
                <div className="dropdown">
                  <button
                    className="btn username-navbar dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="true"
                  >
                    {currentUser.email}
                  </button>

                  <div className="dropdown-menu px-2">
                    <Link
                      to="/update-profile"
                      className="dropdown-item dropdown-item-btn"
                    >
                      Profile
                    </Link>

                    <div className="dropdown-divider"></div>
                    <a
                      className="dropdown-item dropdown-item-btn"
                      href="#"
                      onClick={handleLogout}
                    >
                      Log Out
                    </a>
                  </div>
                </div>
              </li>
            ) : (
              <div className="ms-auto">
                {" "}
                <Link to="/login">
                  <button className="btn px-3">
                    <strong>Log In</strong>
                  </button>
                </Link>
                <Link to="/signup">
                  <button className="btn px-3 navbar-signup-btn">
                    <strong>Sign Up</strong>
                  </button>
                </Link>
              </div>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
