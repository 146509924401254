import React, { useRef, useState } from "react";
import "./LoginSignup.css";
import { useAuth } from "../context/AuthContext";
import { Link, useNavigate, Navigate } from "react-router-dom";
import logo_white from "../images/logo_white.png";
import { updateProfile } from "firebase/auth";

function Signup() {
  const nameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { signup, currentUser } = useAuth(); //, signUpWithGoogle
  const [error, setError] = useState("");
  const [accountSuccess, setAccountSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    console.log("here");
    e.preventDefault();
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      setError("Passwords do not match");
      return;
    }

    signup(emailRef.current.value, passwordRef.current.value)
      .then((currentUserData) => {
        /*
        I got it!
        - Add a createUser function to either the AuthContext or the AppContext.
        - Call that function from here and add the details like name etc.
        - That function will call an API endpoint and create the user document with id and everything. 
          That way I can separate the user creation from the get_movements in the API.
        - Do the same with the Login page, but instead of a function to create an user, 
          the function will retrieve an user data (using the API ofc, I'll need to create another endpoint.)
        - The user data can be added to the AuthContext, most likely to an object that also includes the currentUser.
        */
        //updateProfile(currentUserData, { displayName: nameRef.current.value });
        setError("");
        setLoading(true);

        console.log("[CREATING USER] User Data:", currentUserData);
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            userId: currentUserData.user.multiFactor.user.uid,
          }),
        };

        fetch(`${process.env.REACT_APP_API_URL}/create_user`, options)
          .then((response) => response.json())
          .then((res) => console.log(res))
          .then(() => navigate("/"))
          .catch((err) => console.log("[SIGNUP ERROR]", err));
      })
      .then(() => setAccountSuccess(true))
      .then(() => navigate("/dashboard"))
      .catch((err) => {
        console.error(err);
        setError("Failed to create an account");
      });
    setLoading(false);
  };

  const handleGoogle = (e) => {
    //signUpWithGoogle();
  };

  return currentUser ? (
    <Navigate to="/chat" replace={true} />
  ) : (
    <div className="row">
      <div className="col-12 col-md-5 login-image-container p-4 mx-0">
        <div className="img-text px-4 py-5 d-flex justify-content-start flex-column">
          <Link to="/">
            <img className="logo py-4" src={logo_white} alt="logo" />
          </Link>
          <div className="pt-4">
            <h1>Meet your AI Language Partner</h1>
            <p className="loginpage-subtitles">
              Practice languages by having natural and open-ended conversations.
            </p>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-7 px-0 mx-0">
        <div className="container mt-2 d-md-flex align-items-center justify-content-center auth-container">
          <div className="w-100 px-3 auth-sub-container">
            <div className="card">
              <div className="card-body">
                <h2 className="card-title text-center">
                  Create your account 💪
                </h2>
                {error && <div className="alert alert-danger">{error}</div>}
                {accountSuccess && (
                  <div className="alert alert-success">
                    Account was created successfully. Logging in...{" "}
                    <Link to="/login">Log In!</Link>
                  </div>
                )}
                <form className="form" onSubmit={handleSubmit}>
                  <div className="form-group mt-2 mx-2 p-2" id="email">
                    <div className="form-label">Enter your name</div>
                    <input
                      className="form-control"
                      type="text"
                      ref={nameRef}
                      required
                      placeholder="Tony Stark"
                    />
                  </div>
                  <div className="form-group mt-2 mx-2 p-2" id="email">
                    <div className="form-label">What's your email?</div>
                    <input
                      className="form-control"
                      type="email"
                      ref={emailRef}
                      required
                      placeholder="name@example.com"
                    />
                  </div>
                  <div className="form-group mt-2 mx-2 p-2" id="password">
                    <div className="form-label">Choose a password</div>
                    <input
                      className="form-control"
                      type="password"
                      ref={passwordRef}
                      required
                    />
                  </div>
                  <div
                    className="form-group mt-2 mx-2 p-2"
                    id="password-confirm"
                  >
                    <div className="form-label">Confirm your password</div>
                    <input
                      className="form-control"
                      type="password"
                      ref={passwordConfirmRef}
                      required
                    />
                  </div>
                  <div className="mt-2 mx-2 p-2">
                    <button
                      className="btn w-100 mt-2 submit-btn "
                      type="submit"
                      disabled={loading}
                    >
                      Let's begin!
                    </button>
                  </div>
                </form>
                <div className="w-100 text-center mt-1">
                  Already have an account?{" "}
                  <Link to="/login">
                    <strong>Log In!</strong>
                  </Link>
                </div>
                <div className="mt-2 mx-2 p-2">
                  <button
                    className="btn w-100 mt-2 google-auth-btn d-none"
                    disabled={loading}
                    onClick={handleGoogle}
                  >
                    Continue with Google
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
