import React, { useRef, useState } from "react";
import "./LoginSignup.css";
import { useAuth } from "../context/AuthContext";
import { Link, Navigate } from "react-router-dom";

function ForgotPassword() {
  const emailRef = useRef();
  const { resetPassword } = useAuth();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();

  async function handleSubmit(e) {
    e.preventDefault();

    setMessage("");
    resetPassword(emailRef.current.value)
      .then(() => {
        setError("");
        setLoading(true);
        setMessage("Check your inbox for further instructions.");
      })
      .catch((err) => {
        console.error(err);
        setError("Failed to reset password");
      });
    setLoading(false);
  }

  return !currentUser ? (
    <Navigate to="/" replace={true} />
  ) : (
    <div className="container d-flex align-items-center justify-content-center auth-container">
      <div className="w-100 px-3 auth-sub-container">
        <div className="card">
          <div className="card-body">
            <h2 className="card-title text-center pb-3">
              💭 Forgot your password?
            </h2>
            <span className="mt-2">
              Enter your email below and we'll send instructions straight to
              your email.
            </span>
            {error && <div className="alert alert-danger">{error}</div>}
            {message && <div className="alert alert-success">{message}</div>}
            <form className="form pt-2" onSubmit={handleSubmit}>
              <div className="form-group m-2 p-2" id="email">
                <div className="form-label">Email</div>
                <input
                  className="form-control"
                  type="email"
                  ref={emailRef}
                  required
                  placeholder="name@example.com"
                />
              </div>

              <div className="m-2 p-2">
                <button
                  className="btn w-100 mt-2 submit-btn"
                  type="submit"
                  disabled={loading}
                >
                  Continue
                </button>
              </div>
            </form>
            <div className="w-100 text-center mt-2">
              <Link to="/login">Log In</Link>
            </div>
          </div>
        </div>
        <div className="w-100 text-center mt-2">
          Don't have an account yet? <Link to="/signup">Sign Up!</Link>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
