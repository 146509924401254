import React, { useState, useContext } from "react";
import Chat from "./Chat";
import { AppContext } from "./context/AppContext";
import { useAuth } from "./context/AuthContext";
import { useMutation, useQueryClient } from "react-query";
import {
  sendMessage,
  getCorrections,
  handleStreak,
  useGetVoicesQuery,
  getAudio,
  startStreaming,
  startStreamingNarakeet,
} from "./api/apiFunctions";
import SaveChatBtn from "./SaveChatBtn";
import { TbTrash, TbSend, TbSparkles, TbSettings } from "react-icons/tb";
import { v4 as uuidv4 } from "uuid";
import SpeechRecognition from "./SpeechRecognition";
// import SpeechRecognition2 from "./SpeechRecognition2";
import TypingAnimation from "./utilities/TypingAnimation";
import "./ChatSection.css";
import SettingsModal from "./SettingsModal";
import StreakProgressBar from "./StreakProgressBar";

function ChatSection({
  tutorPanelActive,
  setTutorPanelActive,
  chatStarted,
  setChatStarted,
}) {
  const [userMessage, setUserMessage] = useState("");
  const [sourceLanguage, setSourceLanguage] = useState("English");
  // const [targetLanguage, setTargetLanguage] = useState("Spanish");
  const [apiError, setApiError] = useState(false);
  const [aiTyping, setAiTyping] = useState(false);
  const [isSuggestion, setIsSuggestion] = useState(false); //Used to know whether the user entered their own message, or clicked on a suggestion. B/c suggestions won't get corrections from the api
  const [suggestionsActive, setSuggestionsActive] = useState(false); // Used only for the suggestions caret animation
  const [messagesCount, setMessagesCount] = useState(0);
  const { currentUser } = useAuth();
  const { dispatch, currentChat, currentTargetLanguage, constants } =
    useContext(AppContext);
  const queryClient = useQueryClient();
  const lastMessage = currentChat["messages"].slice(-1)[0];

  // const voices = useGetVoicesQuery();

  // const getAudioMutation = useMutation({
  //   mutationFn: getAudio,
  //   onSuccess: (data) => {},
  //   onError: (data) => {
  //     console.log("Error");
  //   },
  // });

  const sendMessageMutation = useMutation({
    mutationFn: sendMessage,
    onSuccess: (data) => {
      dispatch({
        type: "UPDATE_VERSA_MSG",
        payload: data,
      });

      if (
        constants.elevenLabsLanguages.includes(currentTargetLanguage) &&
        currentChat.chatSettings.audio
      ) {
        startStreaming(data.slice(-1)[0].content);
      }
    },
    onError: () => {
      setApiError(true);
      console.log("There has been an error");
    },
    onSettled: () => {
      setAiTyping(false);
    },
    retry: 3,
  });

  const handleStreakMutation = useMutation({
    mutationFn: handleStreak,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["streakData", currentTargetLanguage],
      });
    },
    onError: () => {
      // setApiError(true);
      setAiTyping(false);
      console.log("There has been an error");
    },
    retry: 3,
  });

  const handleChange = (event) => {
    setUserMessage(event.target.value);
    setIsSuggestion(false);
  };

  const setTargetLanguage = (language) => {
    let currentChatUpdated = { ...currentChat };
    currentChatUpdated = {
      ...currentChatUpdated,
      targetLanguage: language,
    };
    dispatch({
      type: "UPDATE_CURRENT_CHAT",
      payload: currentChatUpdated,
    });
  };

  const handleSelectTopic = (topic) => {
    let currentChatUpdated = { ...currentChat };
    // console.log("⭐", topic);
    // console.log("💪", currentChat["topic"]);
    if (topic["title"] == currentChat.topic?.title) {
      // setCurrentTopic(null);
      currentChatUpdated = {
        ...currentChatUpdated,
        topic: null,
      };
    } else {
      // setCurrentTopic(topic);
      currentChatUpdated = {
        ...currentChatUpdated,
        topic: topic,
      };
    }
    dispatch({
      type: "UPDATE_CURRENT_CHAT",
      payload: currentChatUpdated,
    });
  };

  const handleSendMessage = (event) => {
    event.preventDefault();
    // console.log("🔥🔥", currentUser);
    let userMessageAmount;
    if (chatStarted) {
      userMessageAmount = 1; // starts at 1 to offset the fact that at this point in the function, currentChat does not contain the message the user just sent.
      for (const msg of currentChat["messages"]) {
        if (msg["role"] == "user") {
          userMessageAmount += 1;
        }
      }
    } else {
      userMessageAmount = 0;
    }
    setMessagesCount(userMessageAmount);
    // console.log("🤩 MESSAGES", userMessageAmount, messagesCount);

    if (userMessageAmount == 7) {
      console.log("💪💪 STREAK: 7 CHATS");
      handleStreakMutation.mutate({
        currentUser: currentUser,
        targetLanguage: currentChat.targetLanguage,
        streakData: queryClient.getQueryData([
          "streakData",
          currentTargetLanguage,
        ]),
      });
    } else if (userMessageAmount == 20) {
      console.log("💪💪 STREAK: 20 CHATS");
    }

    // If the user has typed something
    if (userMessage != "" || !chatStarted) {
      let currentChatUpdated = { ...currentChat };

      // If we're starting a new chat
      if (!chatStarted) {
        console.log("STARTING NEW CHAT");

        const optionsLogChat = {
          method: "POST",
          body: JSON.stringify({
            userEmail: currentUser.email,
            topic: currentChat.topic.title,
            targetLanguage: currentTargetLanguage,
          }),
          headers: { "Content-Type": "application/json" },
        };
        fetch(`${process.env.REACT_APP_API_URL}/log_chat`, optionsLogChat).then(
          (response) => response.json()
        );

        setChatStarted(true);
        const initialParams = {
          id: uuidv4(),
          topic: currentChat["topic"]
            ? currentChat["topic"]
            : {
                title: "Open Conversation",
                displayMessage: "by having a conversation.",
                type: null,
              },
          sourceLanguage: sourceLanguage,
          targetLanguage: currentTargetLanguage,
          messages: [],
        };
        currentChatUpdated = { ...currentChatUpdated, ...initialParams };
      } else {
        getCorrections({
          userMessage: userMessage,
          sourceLanguage: sourceLanguage,
          targetLanguage: currentTargetLanguage,
          isSuggestion: isSuggestion,
          dispatch: dispatch,
        });
        const message = { role: "user", content: userMessage };
        currentChatUpdated = {
          ...currentChatUpdated,
          messages: [...currentChat["messages"], message],
        };
      }

      setAiTyping(true);
      setApiError(false);

      dispatch({
        type: "UPDATE_CURRENT_CHAT",
        payload: currentChatUpdated,
      });

      sendMessageMutation.mutate({
        chatHistory: currentChatUpdated["messages"],
        sourceLanguage: sourceLanguage,
        targetLanguage: currentTargetLanguage,
        currentTopic: currentChat["topic"],
        isSuggestion: isSuggestion,
      });
      setUserMessage("");
    }
  };
  const clearChat = (event) => {
    setUserMessage("");
    setMessagesCount(0);
    setTutorPanelActive(false);
    setChatStarted(false);
    dispatch({
      type: "CLEAR_CHAT",
      payload: {},
    });
    dispatch({
      type: "SET_CURRENT_VERSA_MESSAGE",
      payload: null,
    });
    dispatch({
      type: "SET_CURRENT_USER_MESSAGE",
      payload: null,
    });
  };

  const conversationTopics = [
    {
      title: "coffee shop",
      displayMessage: "in a coffee shop ☕",
      type: "situation",
    },
    { title: "gym", displayMessage: "at the gym 🏋️", type: "situation" },
    {
      title: "restaurant",
      displayMessage: "in a restaurant 🍽️",
      type: "situation",
    },
    {
      title: "supermarket",
      displayMessage: "at the supermarket 🛒",
      type: "situation",
    },
    {
      title: "talk about my day",
      displayMessage: "by talking about your day ✨",
      type: "personal",
    },
    {
      title: "hobbies",
      displayMessage: "by talking about your hobbies 🎭",
      type: "personal",
    },
    {
      title: "life goals",
      displayMessage: "by talking about your goals 🎯",
      type: "personal",
    },
    {
      title: "fun memories",
      displayMessage: "by sharing a memory 💭",
      type: "personal",
    },
    {
      title: "gaming",
      displayMessage: "by talking about gaming 🎮",
      type: "interests",
    },
    {
      title: "sports",
      displayMessage: "by talking about sports ⚽",
      type: "interests",
    },
    {
      title: "books",
      displayMessage: "by talking about books 📖",
      type: "interests",
    },
    {
      title: "movies",
      displayMessage: "by talking about movies 🎬",
      type: "interests",
    },
    {
      title: "cooking",
      displayMessage: "by talking about cooking 🧑‍🍳",
      type: "interests",
    },
    {
      title: "productivity",
      displayMessage: "while talking about productivity 💪",
      type: "productivity",
    },
    {
      title: "business",
      displayMessage: "by discussing business tips and ideas 📈",
      type: "productivity",
    },
    {
      title: "study tips",
      displayMessage: "by sharing study tips 🧠",
      type: "productivity",
    },
  ];

  const speak = () => {
    console.log("talkinnn");
    const apikey = "sdXlskdk1P8HJdW9NrjAs61OhmfqsQDO74hBVqnt";
    const url = `https://api.narakeet.com/text-to-speech/m4a?voice=${"luigi"}`;
    const ttsOptions = {
      method: "POST",
      headers: {
        Accept: "application/octet-stream",
        "Content-Type": "text/plain",
        "x-api-key": apikey,
        mode: "cors",
        "Access-Control-Allow-Origin": "*",
      },
      data: "It'sa meee",
    };
    return fetch(url, ttsOptions)
      .then((response) => console.log(response.json()))
      .catch((err) => console.log(err));
  };

  return (
    <div
      className={`main-container main-chatsection-container row d-flex flex-column justify-content-between h-100 ${currentChat.chatSettings.backgroundImage}`}
    >
      {!chatStarted ? (
        <div className="d-flex h-100 px-3 py-2 flex-column justify-content-center">
          <div className="row py-2 justify-content-center language-selection-container">
            <div className="col-auto d-flex align-items-center px-0">
              Let's practice {currentTargetLanguage}!
              {/* I ...
              <div className="dropdown mx-2">
                <button
                  className="dropdown-toggle language-select-dropdown btn btn-sm btn-light"
                  type="button"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="true"
                  data-bs-dismiss="dropdown"
                  disabled={chatStarted}
                >
                  <strong>{sourceLanguage}</strong>
                </button>
                <ul className="dropdown-menu">
                  {supportedLanguages.map((language) => (
                    <li
                      key={language}
                      className="dropdown-item pick-language-btn"
                      onClick={() => setSourceLanguage(language)}
                    >
                      {language}
                    </li>
                  ))}
                </ul>
              </div>
                  */}
            </div>
            {/* <div className="col-auto d-flex align-items-center px-0">
            
              <div className="dropdown mx-2">
                <button
                  className="dropdown-toggle btn btn-sm btn-light language-select-dropdown"
                  type="button"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="true"
                  data-bs-dismiss="dropdown"
                  disabled={chatStarted}
                >
                  <strong>{currentChat["targetLanguage"]}</strong>
                </button>
                <ul className="dropdown-menu">
                  {supportedLanguages.map((language) => (
                    <li
                      key={language}
                      className="dropdown-item dropdown-item-btn"
                      onClick={() => setTargetLanguage(language)}
                    >
                      {language}
                    </li>
                  ))}
                </ul>
              </div>
            </div> */}
          </div>

          <div className="container h-100 py-3 px-0 px-sm-3 d-flex flex-column align-items-center justify-content-center">
            <div
              className={`mt-2 mb-2 px-3 px-lg-5 text-center chat-instructions ${currentChat.chatSettings.backgroundImage}`}
            >
              <strong>What should we talk about today?</strong>
            </div>
            <div className="row px-2 px-lg-5">
              {conversationTopics.map((topic, index) => (
                <div
                  key={index}
                  className={`p-1 ${
                    topic["type"] !== "tutor"
                      ? "col-6 col-sm-4 col-lg-3"
                      : "col-12"
                  } `}
                >
                  <div
                    className={`px-3 py-2 py-sm-2 p-lg-2 text-center conversation-topic-card ${
                      topic["type"]
                    } ${
                      topic.title == currentChat["topic"]?.title
                        ? "conversation-topic-card-selected"
                        : ""
                    }`}
                    onClick={() => handleSelectTopic(topic)}
                  >
                    <strong>{topic["title"]}</strong>
                    <div className="mt-sm-1">
                      <small>
                        {topic["title"] !== "tutor" ? (
                          <div
                            className={`badge rounded-pill px-3 ms-1 topic-type-badge ${topic["type"]}`}
                          >
                            {topic["type"]}
                          </div>
                        ) : (
                          <div className="pb-1">{topic["description"]}</div>
                        )}
                      </small>
                    </div>
                  </div>
                </div>
              ))}

              <button
                id="start-chat-btn"
                className={`btn col-12 mt-3 mb-3 py-3 py-sm-2 ${
                  chatStarted ? "d-none" : ""
                }  ${!currentChat["topic"] ? "button-disabled" : ""}`}
                disabled={!currentChat["topic"]}
                onClick={handleSendMessage}
              >
                <strong>
                  {!currentChat["topic"]
                    ? "First pick a topic"
                    : "Great, let's begin!"}
                </strong>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column h-100">
          {" "}
          <div className="pt-3 pt-sm-2 pb-0 row">
            <div className="col-auto ">
              <button
                className="btn py-sm-2 w-100"
                id="clear-chat-btn"
                type="button"
                onClick={clearChat}
              >
                <TbTrash size="22px" />
              </button>
            </div>
            <div className="col d-flex py-2 py-sm-2 justify-content-center align-items-center conversation-settings-banner">
              <div className="d-none d-sm-flex">{`Let's practice ${
                //currentChat["targetLanguage"]
                currentTargetLanguage
              } ${
                currentChat["topic"]
                  ? currentChat["topic"]?.displayMessage
                  : "by having a conversation!"
              }`}</div>
              <div className="d-flex d-sm-none">
                <div>{`Let's practice ${
                  //currentChat["targetLanguage"]
                  currentTargetLanguage
                }!`}</div>
              </div>
            </div>
            <div className="col-auto">
              <SaveChatBtn />
            </div>
            <div className="col-auto d-md-none ps-0">
              <button
                id="settings-modal-btn"
                className="h-100 px-2"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#settings-modal"
              >
                <TbSettings size="24px" />
              </button>
              <SettingsModal id="settings-modal" />
            </div>
          </div>
          <StreakProgressBar messagesCount={messagesCount} />
          <Chat aiTyping={aiTyping} apiError={apiError} />
          <div className="px-1 px-sm-0">
            <div className={`${aiTyping && chatStarted ? "mb-3" : "d-none"}`}>
              <TypingAnimation />
            </div>

            <div
              className={`alert alert-warning mb-0 ${
                apiError && chatStarted ? "" : "d-none"
              }`}
            >
              ⚠️ Something went wrong, but we're working on it!
            </div>

            <form
              className={`d-flex ${chatStarted ? "" : "d-none"}`}
              onSubmit={handleSendMessage}
            >
              <input
                className="w-100 py-2 px-2 me-2 order-0"
                type="text"
                id="user-input"
                name="user-input"
                onChange={handleChange}
                value={userMessage}
                autoComplete="off"
                placeholder={
                  chatStarted
                    ? "Type a message or pick a suggestion from below."
                    : `Type a message in either ${sourceLanguage} or ${currentTargetLanguage} to start a conversation.`
                }
              />

              <button
                id="send-msg-btn"
                className={`btn ms-2 order-2 ${
                  !currentChat["topic"] || userMessage.length == 0
                    ? "button-disabled"
                    : ""
                }`}
                onClick={handleSendMessage}
                disabled={!currentChat["topic"] || userMessage.length == 0}
              >
                <TbSend size="20px" />
              </button>

              <div className="order-1">
                <SpeechRecognition
                  className=""
                  setUserMessage={setUserMessage}
                />
              </div>
              {/* 
        <div className="order-4">
          <SpeechRecognition2 className="" setUserMessage={setUserMessage} />
        </div>
        */}
              <button
                id="toggle-tutor-panel-btn"
                className={`btn order-3 d-none d-md-block col-auto ms-2 px-3 ${
                  tutorPanelActive ? "active" : ""
                }`}
                onClick={(event) => {
                  event.preventDefault();
                  setTutorPanelActive(!tutorPanelActive);
                }}
              >
                {/* <div id="new-feature-tag" className="badge">
                  New!
                </div> */}
                <TbSparkles className="pe-1 sparkles-icon" size="26px" />
                <strong>Ask Versa!</strong>
              </button>
            </form>

            {/*
      <button
        className="btn btn-lg btn-primary mt-3"
        onClick={getAudioMutation.mutate}
      >
        Hi
      </button>
       */}
            {chatStarted ? (
              <div className="my-2">
                <div
                  className={`btn btn-sm btn-light w-100 text-start px-2 py-1 suggestions-toggle d-flex ${
                    lastMessage?.role != "assistant" ? "disabled" : ""
                  }`}
                  data-bs-toggle="collapse"
                  data-bs-target="#suggestions-container"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  onClick={() => setSuggestionsActive(!suggestionsActive)}
                >
                  <div
                    className={`dropdown-toggle me-2 dropdow-toggle-caret ${
                      suggestionsActive
                        ? "dropdown-toggle-caret-active"
                        : "dropdown-toggle-caret-closed"
                    }`}
                  ></div>
                  Suggestions
                </div>

                <div id="suggestions-container" className="collapse">
                  {lastMessage?.suggestions &&
                    lastMessage.suggestions.map((suggestion, index) => (
                      <button
                        className="message-suggestion badge w-100 p-2 mt-1"
                        key={index}
                        onClick={() => {
                          setUserMessage(suggestion["suggestion"]);
                          setIsSuggestion(true);
                        }}
                      >
                        <div className="suggestion-original text-wrap">
                          {suggestion["suggestion"]}
                        </div>
                        <div className="suggestion-translation text-wrap text-muted pt-1">
                          {suggestion["translation"]}
                        </div>
                      </button>
                    ))}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ChatSection;
