import React, { useState, useEffect, useRef, useContext } from "react";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { saveChat, saveCorrection, saveWord } from "./api/apiFunctions";
import { Scrollbars } from "react-custom-scrollbars-2";
import { v4 as uuidv4 } from "uuid";
import AudioStream from "./AudioStream.tsx";

import { AppContext } from "./context/AppContext";
import { useAuth } from "./context/AuthContext";
import { TbInfoCircle, TbCircleCheck, TbAlertTriangle } from "react-icons/tb";

import { MdOutlineSlowMotionVideo, MdOutlinePlayCircle } from "react-icons/md";
import "./Chat.css";

function Chat() {
  const {
    dispatch,
    currentChat,
    currentVersaMessage,
    currentUserMessage,
    currentTargetLanguage,
    constants,
  } = useContext(AppContext);
  const [savedWords, setSavedWords] = useState([]);
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();
  const scrollbarRef = useRef();
  const audioRef = useRef(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [selectedWord, setSelectedWord] = useState(null);
  let tempVersaMessage = { ...currentVersaMessage };

  async function getWordTranslations(message) {
    let messageToSend = "";

    if (message !== undefined) {
      messageToSend = message;
    }

    return fetch(
      process.env.REACT_APP_API_URL +
        "/get_word_translations?" +
        new URLSearchParams({
          message: messageToSend,
          targetLanguage: currentChat.targetLanguage,
        })
    ).then((response) => response.json());
  }

  const { data } = useQuery({
    queryKey: ["wordByWordTranslation", tempVersaMessage.content],
    queryFn: () => getWordTranslations(tempVersaMessage.content),
    staleTime: Infinity,
    retry: 3,
    enabled: tempVersaMessage.content ? true : false,
  });

  const handleClickVersaMessage = (message) => {
    setSelectedWord(null);
    tempVersaMessage = message;

    queryClient.invalidateQueries({
      queryKey: ["wordByWordTranslation", tempVersaMessage.content],
    });

    if (message["content"] == currentVersaMessage?.content) {
      dispatch({
        type: "SET_CURRENT_VERSA_MESSAGE",
        payload: null,
      });
    } else {
      dispatch({
        type: "SET_CURRENT_VERSA_MESSAGE",
        payload: message,
      });
    }
  };

  const handleClickUserMessage = (message) => {
    if (message["content"] == currentUserMessage?.content) {
      dispatch({
        type: "SET_CURRENT_USER_MESSAGE",
        payload: null,
      });
    } else {
      dispatch({
        type: "SET_CURRENT_USER_MESSAGE",
        payload: message,
      });
    }
  };

  const saveChatMutation = useMutation({
    mutationFn: saveChat,
    onError: () => {
      console.log("Error saving the chat");
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["savedChats"] });
    },
    retry: false,
  });

  const saveCorrectionMutation = useMutation({
    mutationFn: saveCorrection,
    onError: () => {
      console.log("Error saving the correction");
    },
    onSuccess: () => {
      console.log("Correction Saved");
      queryClient.invalidateQueries({ queryKey: ["savedCorrections"] });
    },
    retry: false,
  });

  const handleSaveCorrection = () => {
    console.log("Hi, saving correction");
    console.log("USER MSG", currentUserMessage);
    const correction = {
      id: uuidv4(),
      message: currentUserMessage.content,
      corrected: currentUserMessage.correctedMessage,
      errors: currentUserMessage.errors,
    };
    saveCorrectionMutation.mutate({
      currentUser: currentUser,
      targetLanguage: currentChat["targetLanguage"],
      correction: correction,
    });
  };

  const saveWordMutation = useMutation({
    mutationFn: saveWord,
    onError: () => {
      console.log("Error saving the word");
    },
    onSuccess: ({ data }) => {
      console.log("Word Saved", currentTargetLanguage);
      queryClient.invalidateQueries({
        queryKey: ["savedWords", currentTargetLanguage],
      });
    },
    retry: false,
  });

  const handleSaveWord = ({ targetW, sourceW, sentence, translation }) => {
    saveWordMutation.mutate({
      currentUser: currentUser,
      targetLanguage: currentChat["targetLanguage"],
      targetW: targetW,
      sourceW: sourceW,
      sentence: sentence,
      translation: translation,
    });
    setSavedWords([...savedWords, targetW]);
  };

  // const getMessageSpeech = async (text) => {
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_URL}/get_voice_file?` +
  //         new URLSearchParams({
  //           text: text,
  //         })
  //     );
  //     const data = await response.arrayBuffer();
  //     const array = new Uint8Array(data);
  //     const blob = new Blob([array], {
  //       type: "audio/mpeg",
  //     });
  //     const url = URL.createObjectURL(blob);
  //     console.log("URL", url);
  //     console.log("before", audioUrl);
  //     setAudioUrl(url);
  //     console.log("after", audioUrl);
  //     if (audioRef.current) {
  //       audioRef.current.play();
  //     }
  //   } catch (error) {
  //     console.error("Error fetching audio:", error);
  //   }
  // };

  useEffect(() => {
    // Cada vez que se monta el Chat, o que cambia la lista de chats
    // Deslizar la scrollbar hasta abajo
    scrollbarRef.current.scrollToBottom();

    // If the chat is marked as save, update the chat in the db
    if (currentChat["isSaved"]) {
      saveChatMutation.mutate({
        currentUser: currentUser,
        currentChat: currentChat,
      });
    }
  }, [currentChat["messages"]]);

  const voiceId = "IKne3meq5aSn9XLyUdCD"; //"21m00Tcm4TlvDq8ikWAM";
  const apiKey = process.env.REACT_APP_ELEVEN_LABS_API_KEY;
  const voiceSettings = {
    stability: 1,
    similarity_boost: 0.5,
    speaker_boost: false,
  };

  return (
    <div className="chat-list pt-1 pt-sm-3 pb-3 h-100" key="stupidkey">
      {/* {audioUrl && (
        <audio ref={audioRef} className="d-none" controls>
          <source src={audioUrl} type="audio/mpeg" />
        </audio>
      )} */}

      <Scrollbars
        className="h-100 w-100"
        autoHide={true}
        ref={scrollbarRef}
        autoHideTimeout={400}
        autoHideDuration={600}
        key="scrollbar"
      >
        <div className="mb-3">
          {currentChat["messages"].map((message, index) => {
            return (
              <div key={index}>
                {" "}
                {message["role"] == "assistant" ? (
                  <div className="py-1">
                    <div
                      key={index}
                      className={`row d-flex mx-sm-1 message message-assistant ${
                        currentVersaMessage?.content == message["content"]
                          ? "message-selected-assistant"
                          : ""
                      }`}
                      onClick={() => handleClickVersaMessage(message)}
                    >
                      <div
                        id="message-assistant-info-btn"
                        className={`btn btn-sm col-auto d-flex align-items-center ${
                          currentVersaMessage?.content == message["content"]
                            ? "active"
                            : ""
                        }`}
                      >
                        <TbInfoCircle size="18px" />
                      </div>
                      <div className="col mx-0">
                        <div>{message["content"]}</div>
                        {message.phonetic &&
                          currentChat.chatSettings.phonetic && (
                            <div>
                              <small className="text-muted">
                                {message["phonetic"]}
                              </small>
                            </div>
                          )}
                        {currentChat.chatSettings.translations && (
                          <div>
                            <small className="text-muted">
                              {message["translation"]}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>

                    <div
                      className={`row col-12 mx-0 px-sm-3 d-flex justify-content-start ${
                        currentVersaMessage?.content == message["content"]
                          ? ""
                          : "d-none"
                      }`}
                    >
                      <div className="col-auto p-1 mb-3 message-details-container-versa">
                        <div className="p-2 p-sm-3 pb-2">
                          <strong>{currentVersaMessage?.content}</strong>
                          {message.phonetic && (
                            <div className="text-muted">
                              {message["phonetic"]}
                            </div>
                          )}
                          <div>{currentVersaMessage?.translation}</div>

                          {constants.elevenLabsLanguages.includes(
                            currentTargetLanguage
                          ) && (
                            <div className="d-flex mt-2">
                              <AudioStream
                                id="play-audio-button"
                                voiceId={voiceId}
                                text={currentVersaMessage?.content}
                                apiKey={apiKey}
                                className="btn btn-light"
                                voiceSettings={voiceSettings}
                              >
                                <MdOutlinePlayCircle size="24px" />
                              </AudioStream>
                              <AudioStream
                                id="play-audio-button"
                                voiceId={voiceId}
                                text={currentVersaMessage?.content
                                  .split(" ")
                                  .join("--")}
                                apiKey={apiKey}
                                className="btn btn-light ms-2"
                                voiceSettings={voiceSettings}
                              >
                                <MdOutlineSlowMotionVideo size="24px" />
                              </AudioStream>
                            </div>
                          )}
                          <br />
                          <div>
                            {data?.translations ? (
                              <div>
                                {data["translations"].map(
                                  ([targetW, sourceW], index) =>
                                    Object.keys(
                                      constants.differentScriptLanguages
                                    ).includes(currentTargetLanguage) &&
                                    constants.elevenLabsLanguages.includes(
                                      currentTargetLanguage
                                    ) ? (
                                      <div
                                        key={index}
                                        className={`m-1 px-3 py-1 d-inline-block word-definition ${
                                          savedWords.includes(targetW)
                                            ? "already-saved"
                                            : "not-saved"
                                        } ${
                                          selectedWord?.target == targetW
                                            ? "active"
                                            : "inactive"
                                        }`}
                                        onClick={() => {
                                          console.log(
                                            "Saved Words",
                                            savedWords
                                          );
                                          setSelectedWord({
                                            target: targetW,
                                            source: sourceW,
                                          });
                                        }}
                                        // onClick={() =>
                                        //   getMessageSpeech(targetW)
                                        // }
                                      >
                                        {" "}
                                        <AudioStream
                                          // id="play-audio-button"
                                          voiceId={voiceId}
                                          text={targetW}
                                          apiKey={apiKey}
                                          className=""
                                          voiceSettings={voiceSettings}
                                        >
                                          <small>
                                            <div className="targetW">
                                              {targetW}
                                            </div>
                                            <div className="text-muted">
                                              {sourceW}
                                            </div>
                                          </small>
                                        </AudioStream>
                                      </div>
                                    ) : (
                                      <div
                                        key={index}
                                        className={`m-1 px-3 py-1 d-inline-block word-definition ${
                                          savedWords.includes(targetW)
                                            ? "already-saved"
                                            : "not-saved"
                                        } ${
                                          selectedWord?.target == targetW
                                            ? "active"
                                            : "inactive"
                                        }`}
                                        onClick={() => {
                                          console.log(
                                            "Saved Words",
                                            savedWords
                                          );
                                          setSelectedWord({
                                            target: targetW,
                                            source: sourceW,
                                          });
                                        }}
                                        // onClick={() =>
                                        //   getMessageSpeech(targetW)
                                        // }
                                      >
                                        <small>
                                          <div className="targetW">
                                            {targetW}
                                          </div>
                                          <div className="text-muted">
                                            {sourceW}
                                          </div>
                                        </small>
                                      </div>
                                    )
                                )}
                                <br />
                                <div
                                  id="save-word-btn"
                                  className={`btn mt-3 ${
                                    selectedWord
                                      ? "active"
                                      : "inactive disabled"
                                  }`}
                                  onClick={() =>
                                    handleSaveWord({
                                      targetW: selectedWord["target"],
                                      sourceW: selectedWord["source"],
                                      sentence: message["content"],
                                      translation: message["translation"],
                                    })
                                  }
                                >
                                  save word
                                </div>
                              </div>
                            ) : (
                              <div
                                className="spinner-border spinner-border-sm text-secondary mx-1"
                                role="status"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="">
                    <div className="d-flex justify-content-end">
                      <div
                        key={index}
                        className={`message message-user mx-sm-1 row d-flex ${
                          currentUserMessage?.content == message["content"]
                            ? "message-selected-user"
                            : ""
                        }`}
                        onClick={() => handleClickUserMessage(message)}
                      >
                        <div className="col">
                          <strong> {message["content"]}</strong>
                          {message.translatedMessage && (
                            <div className="">{message.translatedMessage}</div>
                          )}
                        </div>

                        {message.errors ? (
                          message.errors.length > 0 &&
                          !message.wasCorrect &&
                          !message.translatedMessage ? (
                            <div
                              id="message-user-status-btn"
                              className="btn btn-sm col-auto px-2 mx-0 d-flex align-items-center errors"
                            >
                              <TbAlertTriangle size="20px" />
                            </div>
                          ) : (
                            <div
                              id="message-user-status-btn"
                              className="btn btn-sm col-auto px-2 mx-0 d-flex align-items-center correct"
                            >
                              <TbCircleCheck size="20px" />
                            </div>
                          )
                        ) : (
                          <div
                            id="message-user-status-btn"
                            className="btn btn-sm col-auto px-2 mx-0 d-flex align-items-center"
                          >
                            <div
                              className="spinner-border spinner-border-sm text-light mx-1"
                              role="status"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className={`row d-flex mx-0 px-sm-3 justify-content-end ${
                        currentUserMessage?.content == message["content"]
                          ? ""
                          : "d-none"
                      }`}
                    >
                      <div className="col-auto p-1 mb-3 message-details-container-user">
                        <div className="p-2 p-sm-3 pb-2">
                          {currentUserMessage &&
                          currentUserMessage.errors &&
                          currentUserMessage.errors.length > 0 &&
                          !message.translatedMessage &&
                          !currentUserMessage.wasCorrect ? ( // careful w this line. Errors like "Avengers is not a drama movie" will cause the msg icon to be ⚠️ and the correction to be the same as the original msg. I need to refine the prompt so it doesn't throw these errors where the correction is the same as the original msg.
                            <>
                              {currentUserMessage?.correctedMessage && (
                                <div>
                                  <div className="text-muted">
                                    {"⚠️ "}
                                    <>{message.content}</>
                                  </div>
                                  <div>
                                    <strong>
                                      {"✅ "}
                                      {currentUserMessage?.correctedMessage}
                                    </strong>
                                  </div>
                                </div>
                              )}

                              <div className="pt-4">
                                <strong className="">
                                  We found {currentUserMessage?.errors.length}{" "}
                                  improvement(s)
                                </strong>

                                <div className="pb-2">
                                  {currentUserMessage.errors.map(
                                    (errorObject, index) => (
                                      <div
                                        key={index}
                                        className="error-detail px-3 py-2 mt-2"
                                      >
                                        <strong>{errorObject.error}</strong>
                                        <div>{errorObject.explanation}</div>
                                      </div>
                                    )
                                  )}
                                </div>
                                {/* 
                                    <hr className="mt-4 mb-2" />
                                    <small className="text-muted">
                                      This feature is in
                                      <span className="badge rounded-pill px-3 ms-1 feature-tag coming-soon">
                                        Beta
                                      </span>
                                      , which means some of the language errors
                                      listed may ocassionally be incorrect or
                                      redundant.
                                    </small>
                                  */}
                                {/* <div className="pt-3">
                                  <div
                                    id="save-corrections-btn"
                                    className="btn btn-light btn-sm px-3"
                                    onClick={handleSaveCorrection}
                                  >
                                    save corrections
                                  </div>
                                  <div className="px-2">
                                    <small className="text-muted">
                                      Press the button to save these
                                      corrections. You can review them later in
                                      the "library" section!
                                    </small>
                                  </div>
                                </div> */}
                              </div>
                            </>
                          ) : (
                            <>
                              <h4>🥳All good!</h4>
                              <div>This message seems to have no errors.</div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </Scrollbars>
    </div>
  );
}

export default Chat;

/*
{chatHistory.map((message, index) =>
    message["role"] == "assistant" ? (
      <OverlayTrigger
        key={index}
        trigger="hover"
        rootClose={true}
        placement="left"
        overlay={popover(message["translation"])}
      >
        <div
          className={`message message-assistant my-1 p-3`}
          key={message["content"]}
          onClick={setCurrentMessage(message)}
        >
          {message["content"]}
        </div>
      </OverlayTrigger>
    ) : (
      <div
        className={`message message-user my-4`}
        key={message["content"]}
      >
        {message["content"]}
      </div>
    )
  )} */
