import { createContext, useReducer } from "react";
import { v4 as uuidv4 } from "uuid";

const AppReducer = (state, action) => {
  switch (action.type) {
    case "START_NEW_CHAT":
      const newState = {
        ...state,
        currentChat: {
          ...state["currentChat"],
          id: uuidv4(),
          ...action.payload,
        },
      };

      return newState;

    case "UPDATE_CURRENT_CHAT":
      return { ...state, currentChat: action.payload };

    case "UPDATE_USER_MSG":
      // Update the user message (from the chat history, NOT the "current user message"), by adding the corrections.
      let lastUserMessage;
      let lastUserMessageIdx = null;
      if (state["currentChat"]["messages"].slice(-1)[0]["role"] == "user") {
        lastUserMessage = state["currentChat"]["messages"].slice(-1)[0];
        lastUserMessageIdx = -1;
      } else {
        lastUserMessage = state["currentChat"]["messages"].slice(-2)[0];
        lastUserMessageIdx = -2;
      }
      // Agregarle las correcciones.
      const userMessage2 = {
        role: "user",
        content: lastUserMessage["content"],
        correctedMessage: action.payload["corrected_message"],
        translatedMessage: action.payload["translated_message"],
        errors: action.payload["errors"],
        wasCorrect:
          action.payload["corrected_message"] == lastUserMessage["content"],
      };

      let chatHistoryCopy = [...state["currentChat"]["messages"]];
      chatHistoryCopy.splice(lastUserMessageIdx, 1, userMessage2);

      return {
        ...state,
        currentChat: { ...state["currentChat"], messages: chatHistoryCopy },
      };
    case "UPDATE_VERSA_MSG":
      // Here take the input from the api and update the shit out of the versa message.
      const lastMessage = action.payload.slice(-1)[0];

      const versaMessage = {
        role: "assistant",
        content: lastMessage["content"],
        translation: lastMessage["translation"],
        suggestions: lastMessage["suggestions"],
      };
      if (Object.keys(lastMessage).includes("phonetic")) {
        versaMessage.phonetic = lastMessage.phonetic;
      }
      let chatHistoryUpdated = [
        ...state["currentChat"]["messages"],
        versaMessage,
      ];

      return {
        ...state,
        currentChat: { ...state["currentChat"], messages: chatHistoryUpdated },
      };
    case "UPDATE_TUTOR_MSG":
      return { ...state, currentTutorMessage: action.payload };
    case "CLEAR_CHAT":
      // This only clears the chat. Its purpose is not to go back to the initial state,
      // but rather reset only the settings related to the chat.
      // Maybe in the future, currentVersaMessage, and currentUserMessage should be inside the currentChat.
      return {
        ...initialState,
        currentChat: {
          ...initialState.currentChat,
          chatSettings: state.currentChat.chatSettings,
        },
        coursesList: state.coursesList,
        currentTargetLanguage: state.currentTargetLanguage,
      };

    case "SET_CURRENT_VERSA_MESSAGE":
      return { ...state, currentVersaMessage: action.payload };
    case "SET_CURRENT_USER_MESSAGE":
      return { ...state, currentUserMessage: action.payload };

    case "UPDATE_CHAT_SETTINGS":
      localStorage.setItem("chatSettings", JSON.stringify(action.payload));
      return {
        ...state,
        currentChat: { ...state.currentChat, chatSettings: action.payload },
      };

    case "UPDATE_CURRENT_TARGET_LANGUAGE":
      localStorage.setItem(
        "currentTargetLanguage",
        JSON.stringify(action.payload)
      );
      return { ...state, currentTargetLanguage: action.payload };
    case "UPDATE_COURSES_LIST":
      // add or remove languages that the user is learning
      localStorage.setItem("coursesList", JSON.stringify(action.payload));
      return { ...state, coursesList: action.payload };
  }
};

const constants = {
  supportedLanguages: [
    "Spanish",
    "Italian",
    "Portuguese",
    "French",
    "German",
    "Luxembourgish",
    "Hungarian",
    "Polish",
    "Hindi",
    "Swahili",
    "Chinese",
    "Japanese",
    "Korean",
    "Russian",
  ],
  elevenLabsLanguages: [
    "English",
    "Japanese",
    "Chinese",
    "German",
    "Hindi",
    "French",
    "Korean",
    "Portuguese",
    "Italian",
    "Spanish",
    "Indonesian",
    "Dutch",
    "Turkish",
    "Filipino",
    "Polish",
    "Swedish",
    "Bulgarian",
    "Romanian",
    "Arabic",
    "Czech",
    "Greek",
    "Finnish",
    "Croatian",
    "Malay",
    "Slovak",
    "Danish",
    "Tamil",
    "Ukrainian",
  ],
  greetings: {
    Russian: "привет",
    Italian: "Ciao",
    French: "Salut",
    Spanish: "Hola",
    Portuguese: "Olá",
    Luxembourgish: "Moien",
    German: "Hallo",
    Polish: "Cześć",
    Hungarian: "Szia",
    Chinese: "你好",
    Japanese: "こんにちは",
    Korean: "안녕하세요",
    Hindi: "नमस्ते",
    Swahili: "Habari",
  },
  differentScriptLanguages: {
    Chinese: "Pinyin",
    Japanese: "Romanji",
    Russian: "Phonetic",
    Hindi: "Phonetic",
    Korean: "Phonetic",
  },
  backgrounds: [
    { imgName: "library", imgId: "library" },
    { imgName: "coffee", imgId: "coffee_shop" },
    { imgName: "nature", imgId: "nature" },
    { imgName: "gray", imgId: "gray-bg" },
    { imgName: "dark", imgId: "dark-bg" },
  ],
};

const initialState = {
  currentChat: {
    id: null,
    topic: null,
    sourceLanguage: null,
    targetLanguage: null,
    isSaved: false,
    messages: [],
    chatSettings: localStorage.getItem("chatSettings")
      ? JSON.parse(localStorage.getItem("chatSettings"))
      : {
          audio: false,
          phonetic: false,
          translations: false,
          backgroundImage: "",
        },
  },
  currentVersaMessage: null,
  currentUserMessage: null,
  currentTutorMessage: null,
  currentTargetLanguage: localStorage.getItem("currentTargetLanguage")
    ? JSON.parse(localStorage.getItem("currentTargetLanguage"))
    : null,
  coursesList: localStorage.getItem("coursesList")
    ? JSON.parse(localStorage.getItem("coursesList"))
    : [],
};

export const AppContext = createContext();

export const AppProvider = (props) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  return (
    <AppContext.Provider
      value={{
        currentChat: state.currentChat,
        currentVersaMessage: state.currentVersaMessage,
        currentUserMessage: state.currentUserMessage,
        currentTutorMessage: state.currentTutorMessage,
        currentTargetLanguage: state.currentTargetLanguage,
        coursesList: state.coursesList,
        constants: constants,
        dispatch,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
