import React, { useRef, useState } from "react";
import "./LoginSignup.css";
import { useAuth } from "../context/AuthContext";
import { Link, useNavigate, Navigate } from "react-router-dom";

function UpdateProfile() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { currentUser, updateEmail, updatePassword } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    console.log("here");
    e.preventDefault();
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      setError("Passwords do not match");
      return;
    }

    const promises = [];
    setLoading(true);
    setError("");
    if (emailRef.current.value !== currentUser.email) {
      promises.push(updateEmail(emailRef.current.value));
    }

    if (passwordRef.current.value) {
      promises.push(updatePassword(passwordRef.current.value));
    }

    Promise.apply(promises)
      .then(() => {
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        setError("Failed to update account");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return !currentUser ? (
    <Navigate to="/" replace={true} />
  ) : (
    <div className="container d-flex align-items-center justify-content-center auth-container">
      <div className="w-100 px-3 auth-sub-container">
        <div className="card">
          <div className="card-body">
            <h2 className="card-title text-center pb-3">
              📝 Update your information
            </h2>
            {error && <div className="alert alert-danger">{error}</div>}
            <form className="form" onSubmit={handleSubmit}>
              <div className="form-group m-2 p-2" id="email">
                <div className="form-label">Email</div>
                <input
                  className="form-control"
                  type="email"
                  ref={emailRef}
                  defaultValue={currentUser.email}
                />
              </div>
              <div className="form-group m-2 p-2" id="password">
                <div className="form-label">Password</div>
                <input
                  className="form-control"
                  type="password"
                  ref={passwordRef}
                  placeholder="Leave blank to keep your current password"
                />
              </div>
              <div className="form-group m-2 p-2" id="password-confirm">
                <div className="form-label">Confirm your password</div>
                <input
                  className="form-control"
                  type="password"
                  ref={passwordConfirmRef}
                  required
                  placeholder="Leave blank to keep your current password"
                />
              </div>
              <div className="m-2 p-2">
                <button
                  className="btn w-100 mt-2 submit-btn"
                  type="submit"
                  disabled={loading}
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="w-100 text-center mt-2">
          <Link to="/">Cancel</Link>
        </div>
      </div>
    </div>
  );
}

export default UpdateProfile;
