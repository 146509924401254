import React, { useState, useContext } from "react";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { useAuth } from "../context/AuthContext";
import { AppContext } from "../context/AppContext";
import { addLanguage } from "../api/apiFunctions";
import Navbar from "../Navbar";
import { Link, Navigate } from "react-router-dom";
import { TbCirclePlus, TbCaretRight } from "react-icons/tb";
import "./Dashboard.css";
import AddLanguageModal from "../AddLanguageModal";
import chatImage from "../images/chat_9.webp";
import vocabImage from "../images/studying.webp";
import FeedbackFormBtn from "../utilities/FeedbackFormBtn";
import ResourcesSection from "../utilities/ResourcesSection";

function Dashboard() {
  const [selectedLanguage, setSelectedLanguage] = useState("Spanish");
  const [isLanguageSelectionOpen, setIsLanguageSelectionOpen] = useState(false);
  const { dispatch, currentTargetLanguage, coursesList, constants } =
    useContext(AppContext);
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();

  // Get the date of the last chat to see if there's a streak

  // Gets the date of the last chat and the days of streak.
  const streakDataQuery = useQuery({
    queryKey: ["streakData", currentTargetLanguage],
    queryFn: () =>
      fetch(
        process.env.REACT_APP_API_URL +
          "/get_streak_data?" +
          new URLSearchParams({
            userId: currentUser.uid,
            targetLanguage: currentTargetLanguage,
          })
      ).then((response) => response.json()),
    staleTime: Infinity,
  });

  /* 
  it takes 2 variables, date of last chat, and amount of chats (streak)

  if the date is less than yesterday, show 0
  if the date is yesterday, show streak
  if the date is today, show streak
  */

  const addLanguageMutation = useMutation({
    mutationFn: addLanguage,
    onError: () => {
      console.log("Error adding language");
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["coursesList"] });
      dispatch({
        type: "UPDATE_CURRENT_TARGET_LANGUAGE",
        payload: selectedLanguage,
      });
    },
    retry: false,
  });

  const addUserLanguage = () => {
    if (!coursesList.includes(selectedLanguage)) {
      // TODO: Use a Mutation to add the selectedLanguage to the list of languages that the user is learning
      addLanguageMutation.mutate({
        currentUser: currentUser,
        language: selectedLanguage,
      });
    } else {
      console.log("The course already exists!");
    }
  };

  async function getCoursesList() {
    return fetch(
      process.env.REACT_APP_API_URL +
        "/get_courses_list?" +
        new URLSearchParams({
          userId: currentUser.uid,
        })
    ).then((response) => response.json());
  }

  const coursesListQuery = useQuery({
    queryKey: ["coursesList"],
    queryFn: () => getCoursesList(),
    staleTime: Infinity,
    retry: 3,
    onSuccess: (data) => {
      console.log("This is the data", data);
      dispatch({
        type: "UPDATE_COURSES_LIST",
        payload: data,
      });
    },
  });

  const updateLang = (language) => {
    dispatch({
      type: "UPDATE_CURRENT_TARGET_LANGUAGE",
      payload: language,
    });
    setIsLanguageSelectionOpen(!isLanguageSelectionOpen);
    console.log(language);
  };

  return !currentUser ? (
    <Navigate to="/login" replace={true} />
  ) : (
    <div className="h-100">
      <Navbar />
      <div className="px-md-5">
        <div className="courses-language-selection pt-2 pt-lg-5 pb-2 px-2 px-md-5">
          {Array.isArray(coursesList)
            ? coursesList.map((language, index) => (
                <div
                  key={index}
                  className={`btn language-selection-btn col-auto me-2 mb-2 ${
                    currentTargetLanguage == language
                      ? "selected"
                      : !isLanguageSelectionOpen
                      ? "d-none"
                      : ""
                  } `}
                  onClick={() => updateLang(language)}
                >
                  {language}
                </div>
              ))
            : ""}

          <button
            className={`btn btn-light col-auto me-2 mb-2 ${
              coursesList.length == 1
                ? ""
                : !isLanguageSelectionOpen
                ? "d-none"
                : ""
            }`}
            id="add-language-btn"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#add-language-modal"
          >
            <TbCirclePlus size="18px" />
          </button>

          <button
            className={`btn btn-light col-auto mb-2 px-2 ${
              coursesList.length > 1 && !isLanguageSelectionOpen ? "" : "d-none"
            }`}
            id="toggle-lang-selection-menu-btn"
            onClick={() => setIsLanguageSelectionOpen(!isLanguageSelectionOpen)}
          >
            <TbCaretRight size="20px" />
          </button>

          <AddLanguageModal id="add-language-modal" />
        </div>
        <div className="container-fluid">
          <div className="main-page-container">
            {coursesListQuery.isLoading && coursesList.length == 0 ? (
              <div className="w-100 mh-100 d-flex flex-column justify-content-center align-items-center pt-5 mt-5">
                <div className="spinner-border" />
              </div>
            ) : coursesListQuery.data?.length == 0 ? (
              <div className="px-4">
                <div className="m-4">
                  <div className="course-section px-4 pt-3 pb-4 mt-2 h-100 d-flex flex-column justify-content-center align-items-center">
                    <h1 className="mt-4">Let's start a course!</h1>
                    <p className="pt-3">
                      Press the button, and pick the first language you'd like
                      to practice.
                    </p>
                    <div className="row d-flex justify-content-center ps-1 mt-3 w-50">
                      {constants.supportedLanguages.map((language, index) => (
                        <div
                          id={index}
                          key={language}
                          className={`col-auto me-1 supported-language-pill ${
                            language == selectedLanguage ? "selected" : ""
                          }`}
                          onClick={() => setSelectedLanguage(language)}
                        >
                          {language}
                        </div>
                      ))}
                    </div>
                    <button
                      id="submit-add-language-btn"
                      className="btn px-3 mt-4"
                      onClick={addUserLanguage}
                    >
                      <strong>Let's begin</strong>
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="px-sm-2 px-md-3 px-lg-4">
                <div className="mb-4">
                  {/* <div className="mb-4">
              <small className="text-muted">
                News and updates | Social (see what your friends are saying,
                studying) - Versa Moots | Add a status
              </small>
            </div> */}

                  <div className="course-section px-3 px-sm-3 pt-3 pb-4">
                    <h2>
                      <strong>
                        {constants.greetings[currentTargetLanguage]}
                      </strong>
                      , let's begin!
                    </h2>
                    {/* {streakDataQuery.data && (
                      <div>
                        <h3>{streakDataQuery.data.streak} days</h3>
                        <h3>{streakDataQuery.data.dateLastChat} days</h3>
                        <h3>{streakDataQuery.data.totalChats} </h3>
                      </div>
                    )} */}
                    {/* This will at some point be replaced with the user's name */}
                    <p className="lead">
                      Ready to practice your {currentTargetLanguage}?
                    </p>
                    {/* <div className="mb-3">
                <small className="text-muted">
                  Streaks | Chat | Drills | My Vocab - Review
                </small>
              </div> */}

                    <div className="row">
                      <div className="col-12 col-sm-6 col-lg-5 col-xl-5 mh-100 p-1 mb-3 mb-sm-0">
                        <div className="card chat-card-container h-100">
                          <img className="card-img-top" src={chatImage} />
                          <div className="card-body p-4">
                            <h4 className="">Chat</h4>
                            <div className="lead">
                              Practice your skills by talking to Versa in{" "}
                              {currentTargetLanguage} about a variety of topics.
                            </div>
                            <Link
                              to="/chat"
                              className="btn btn-dark w-100 py-2 mt-2 dashboard-card-btn"
                            >
                              Start chat
                            </Link>
                          </div>
                          <div className="chat-streak-badge px-3 pt-2 py-0">
                            <h5>
                              {streakDataQuery.data?.streak
                                ? streakDataQuery.data?.streak
                                : 0}{" "}
                              🔥
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-lg-3 mh-100 p-1 mb-3 mb-sm-0">
                        <div className="drill-card-container h-100">
                          <img className="card-img-top" src={vocabImage} />
                          <div className="card-body p-4 ">
                            <h4 className="">Vocab</h4>
                            <div className="lead">
                              Review your saved words and improve your
                              vocabulary.
                            </div>
                            <Link
                              to="/vocab"
                              className="btn btn-dark w-100 py-2 mt-2 dashboard-card-btn"
                            >
                              Review vocabulary
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-4 mh-100 px-1 pb-1 mb-3 mb-sm-0 d-flex flex-column justify-content-start align-items-start">
                        <div className="mb-1 w-100">
                          <FeedbackFormBtn />
                        </div>
                        <div className="h-100 w-100">
                          <ResourcesSection />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
