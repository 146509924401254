import React from "react";
import "./FeedbackFormBtn.css";

function FeedbackFormBtn() {
  return (
    <div className="drill-card-container p-4 my-1">
      <div className="text-start">
        <h4>Have a minute? 🎉</h4>
        <div>
          {"Help improve Versa by answering "}
          <a href="https://airtable.com/shr8GvxkD41EeaESv" target="_blank">
            <strong className="feedback-form-cta">this quick survey!</strong>
          </a>
        </div>
      </div>
    </div>
  );
}

export default FeedbackFormBtn;
