import React, { useState, useEffect, useContext, useRef } from "react";
import { AppContext } from "./context/AppContext";
import { TbSend, TbCircleArrowRight, TbCircleArrowLeft } from "react-icons/tb";
import { useMutation } from "react-query";
import { sendTutorMessage } from "./api/apiFunctions";
import { Scrollbars } from "react-custom-scrollbars-2";
import "./TutorPanel.css";

function TutorPanel({ tutorPanelActive, isTutorPanelActive }) {
  const [userQuestion, setUserQuestion] = useState("");
  const [inHomePanel, setInHomePanel] = useState(true);
  const { dispatch, currentChat, currentTutorMessage } = useContext(AppContext);

  const [tutorCommand, setTutorCommand] = useState("");
  const [beforeVal, setBeforeVal] = useState("");
  const scrollbarRef = useRef();
  const sbContainerRef = useRef(0);
  const [sbHeight, setSBHeight] = useState();
  const [isLoadingAnswer, setIsLoadingAnswer] = useState(false);

  const tutorFeatures = [
    {
      featureId: "/phrasing",
      displayMsg: '"What\'s a polite way of asking for a favor?"',
    },
    {
      featureId: "/vocab",
      displayMsg: '"Can you give me a list of the most common verbs?"',
    },
    {
      featureId: "/grammar",
      displayMsg: '"How is the past tense formed? Give me an example."',
    },
    {
      featureId: "/examples",
      displayMsg: '"Give me examples of sentences in the future tense."',
    },
  ];

  const tutorFeaturesList = tutorFeatures.map(({ featureId }) => featureId);

  const sendTutorMessageMutation = useMutation({
    mutationFn: sendTutorMessage,
    onSuccess: (data) => {
      dispatch({
        type: "UPDATE_TUTOR_MSG",
        payload: data,
      });
      setInHomePanel(false);
      setIsLoadingAnswer(false);
    },
  });

  const handleSendMessage = (event) => {
    event.preventDefault();
    sendTutorMessageMutation.mutate({
      targetLanguage: currentChat["targetLanguage"],
      currentTopic: currentChat["topic"],
      tutorCommand: tutorCommand,
      userQuestion: userQuestion,
    });
    setUserQuestion("");
    setIsLoadingAnswer(true);
  };

  const handleChange = (event) => {
    setUserQuestion(event.target.value);

    if (tutorFeaturesList.includes(event.target.value)) {
      setTutorCommand(event.target.value);
      setUserQuestion("");
    }
  };

  const handleTagRemove = (event) => {
    if ((beforeVal == event.target.value) & (beforeVal == "")) {
      setUserQuestion(tutorCommand.slice(0, -1));
      setTutorCommand("");
    }
  };

  useEffect(() => {
    if (!sbContainerRef?.current?.clientHeight) {
      return;
    }

    setSBHeight(sbContainerRef?.current?.clientWidth);
  }, [sbContainerRef?.current?.clientHeight]);
  return (
    <div
      className={`col-auto d-none tutor-panel-container d-md-flex flex-column align-items-center justify-content-between p-0 m-0 h-100 ${
        tutorPanelActive ? "active py-3 px-3 ms-3" : "inactive"
      }`}
    >
      {!tutorPanelActive ? (
        <></>
      ) : !isLoadingAnswer ? (
        <div
          className="d-flex flex-column justify-content-between h-100 w-100 p-3 pt-5"
          ref={sbContainerRef}
        >
          {inHomePanel ? (
            currentTutorMessage ? (
              <button
                className="btn tutor-panel-navigation-btn mt-2"
                onClick={() => setInHomePanel(false)}
              >
                <TbCircleArrowRight size="26px" />
              </button>
            ) : (
              <></>
            )
          ) : (
            <button
              className="btn tutor-panel-navigation-btn mt-2"
              onClick={() => setInHomePanel(true)}
            >
              <TbCircleArrowLeft size="26px" />
            </button>
          )}

          <Scrollbars
            className="w-100 h-100"
            style={{
              width: sbContainerRef,
              height: sbContainerRef,
            }}
            autoHide={true}
            ref={scrollbarRef}
            autoHideTimeout={400}
            autoHideDuration={600}
            key="scrollbar"
          >
            {" "}
            {inHomePanel ? (
              <>
                <h3>🎓 How can I help you today?</h3>
                <div>
                  I'm here to help you with all your language related questions.
                  Some things you can ask me:
                </div>
                <div className="mt-3">
                  {tutorFeatures.map((feature) => (
                    <div
                      key={feature["featureId"]}
                      className="px-3 py-2 mt-2 tutor-feature-container"
                      onClick={() => setTutorCommand(feature["featureId"])}
                    >
                      <div>
                        <div className="tutor-command px-2 mb-1 badge">
                          {feature["featureId"]}
                        </div>
                        <div className="text-muted">
                          {feature["displayMsg"]}
                        </div>
                      </div>
                    </div>
                  ))}
                  <div
                    key="general"
                    className="px-3 py-2 mt-2 d-block tutor-feature-container"
                    onClick={() => setTutorCommand("")}
                  >
                    <strong className="mb-1">Or ask a general question!</strong>
                    <div className="text-muted">
                      "Where is this language spoken?"
                    </div>
                    <div className="text-muted">
                      "Tell me about the history of the language"
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="h-100">
                  {currentTutorMessage?.tutor_command == "/grammar" ? (
                    <>
                      <h4>{currentTutorMessage?.title}</h4>
                      <div>
                        {currentTutorMessage["explanation"]
                          .split("\n")
                          .map((paragraph, index) => (
                            <p key={index} className={index}>
                              {paragraph}
                            </p>
                          ))}
                      </div>
                      <div className="mt-3">
                        {currentTutorMessage["examples"].map(
                          ([sentence, translation], index) => (
                            <div
                              key={index}
                              className="px-3 py-2 d-block m-1 mt-2 phrasing-item"
                            >
                              <strong>{sentence}</strong>
                              <div>{translation}</div>
                            </div>
                          )
                        )}
                      </div>
                    </>
                  ) : currentTutorMessage?.tutor_command == "/vocab" ? (
                    <>
                      <div>{currentTutorMessage["response"]}</div>
                      <div className="mt-3 px-1">
                        {currentTutorMessage["vocab"].map(([word, meaning]) => (
                          <div
                            key={word}
                            className="vocab-item px-3 py-2 d-inline-block row m-1"
                          >
                            <strong>{word}</strong>
                            <div className="text-muted">{meaning}</div>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : currentTutorMessage?.tutor_command == "/phrasing" ? (
                    <>
                      <div>{currentTutorMessage["response"]}</div>
                      <div className="mt-3">
                        {currentTutorMessage["phrases"].map(
                          ([tone, sentence, translation]) => (
                            <div
                              key={tone}
                              className="px-3 py-2 d-block m-1 mt-2 phrasing-item"
                            >
                              <strong>{tone}</strong>
                              <div>{sentence}</div>
                              <div className="text-muted">{translation}</div>
                            </div>
                          )
                        )}
                      </div>
                    </>
                  ) : currentTutorMessage?.tutor_command == "/examples" ? (
                    <>
                      <div>{currentTutorMessage["response"]}</div>
                      <div className="mt-3">
                        {currentTutorMessage["sentences"].map(
                          ([sentence, translation], index) => (
                            <div
                              key={index}
                              className="px-3 py-2 d-block m-1 mt-2 phrasing-item"
                            >
                              <strong>{sentence}</strong>
                              <div>{translation}</div>
                            </div>
                          )
                        )}
                      </div>
                    </>
                  ) : currentTutorMessage ? (
                    <div>
                      <h4>{currentTutorMessage?.title}</h4>
                      <div>
                        {currentTutorMessage?.explanation
                          .split("\n")
                          .map((paragraph, index) => (
                            <p className={index}>{paragraph}</p>
                          ))}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            )}
          </Scrollbars>
        </div>
      ) : (
        <div className="w-100 h-100 text-dark d-flex flex-column justify-content-center align-items-center">
          <div className="spinner-border spinner-border-lg" />
        </div>
      )}

      {tutorPanelActive && (
        <form className="d-flex mt-2 align-bottom w-100">
          <div id="user-input" className="d-flex w-100 me-2">
            {tutorCommand && (
              <div className="tutor-command px-2 badge ms-2 my-2">
                {tutorCommand}
              </div>
            )}
            <input
              id="user-input-field-id"
              className="user-input-field w-100 px-2 py-2"
              type="text"
              name="user-input"
              onKeyDown={(event) => setBeforeVal(event.target.value)}
              onChange={handleChange}
              onKeyUp={handleTagRemove}
              value={userQuestion}
              autoComplete="off"
              placeholder='Type a "/" command or ask a question'
            />
          </div>
          <button
            className={`btn`}
            id="send-msg-btn"
            onClick={handleSendMessage}
            disabled={false}
          >
            <TbSend size="20px" />
          </button>
        </form>
      )}
    </div>
  );
}

export default TutorPanel;
