// AudioStream.tsx
import React, { useState, ReactNode } from "react";
import axios from "axios";

interface VoiceSettings {
  stability: number;
  similarity_boost: number;
}

interface AudioStreamProps {
  voiceId: string;
  text: string;
  apiKey: string;
  className: string;
  id: string;
  voiceSettings: VoiceSettings;
  children: ReactNode;
}

const AudioStream: React.FC<AudioStreamProps> = ({
  voiceId,
  text,
  apiKey,
  voiceSettings,
  className,
  id,
  children,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Versión "original", sin streaming!!!
  // const startStreaming = async () => {
  //   setLoading(true);
  //   setError("");
  //   if (loading) {
  //     console.log("LOADING");
  //     return;
  //   }

  //   const baseUrl = "https://api.elevenlabs.io/v1/text-to-speech";
  //   const headers = {
  //     "Content-Type": "application/json",
  //     "xi-api-key": apiKey,
  //   };

  //   const requestBody = {
  //     text,
  //     voice_settings: voiceSettings,
  //     model_id: "eleven_multilingual_v2",
  //   };

  //   try {
  //     const response = await axios.post(
  //       `${baseUrl}/${voiceId}/stream`,
  //       requestBody,
  //       {
  //         headers,
  //         responseType: "blob",
  //       }
  //     );
  //     if (response.status === 200) {
  //       const audio = new Audio(URL.createObjectURL(response.data));
  //       audio.play();
  //     } else {
  //       setError("Error: Unable to stream audio.");
  //     }
  //   } catch (error) {
  //     setError("Error: Unable to stream audio.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const startStreaming = async () => {
    setLoading(true);
    setError("");
    console.log("streaming", text);
    if (loading) {
      console.log("LOADING");
      return;
    }

    const audioContext = new window.AudioContext();
    const audioElement = new Audio();
    const baseUrl = "https://api.elevenlabs.io/v1/text-to-speech";
    const headers = {
      "Content-Type": "application/json",
      "xi-api-key": apiKey,
    };

    const requestBody = {
      text,
      voice_settings: voiceSettings,
      model_id: "eleven_multilingual_v2",
    };

    try {
      const response = await axios.post(
        `${baseUrl}/${voiceId}/stream`,
        requestBody,
        {
          headers,
          responseType: "arraybuffer",
        }
      );

      if (response.status === 200) {
        // const audio = new Audio(URL.createObjectURL(response.data));
        console.log(response.data);
        const audioBuffer = await audioContext.decodeAudioData(response.data);
        console.log(audioBuffer);
        const source = audioContext.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(audioContext.destination);
        source.start();
        audioElement.play();
        // audio.play();
      } else {
        setError("Error: Unable to stream audio.");
      }
    } catch (error) {
      setError("Error: Unable to stream audio.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div
        id={id}
        onClick={startStreaming}
        className={className}
        // disabled={loading}
      >
        {children}
      </div>
      {error && <p>{error}</p>}
    </div>
  );
};

export default AudioStream;
