import React, { useContext } from "react";
import { AppContext } from "./context/AppContext";
import { useSavedChatsQuery, unsaveChat } from "./api/apiFunctions";
import { useAuth } from "./context/AuthContext";
import { useMutation, useQueryClient } from "react-query";
import { TbCircleX, TbDots } from "react-icons/tb";

function SavedChatsSection({ mode, setChatStarted }) {
  const { currentUser } = useAuth();
  const { dispatch, currentChat, currentTargetLanguage } =
    useContext(AppContext);
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: unsaveChat,
    onError: () => {
      console.log("Error unsaving the chat");
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["savedChats"] });
    },
    retry: false,
  });

  const handleUnsaveChat = (chat) => {
    if (chat["id"] == currentChat?.id) {
      let currentChatUpdated = { ...currentChat };
      currentChatUpdated = {
        ...currentChatUpdated,
        isSaved: false,
      };
      dispatch({
        type: "UPDATE_CURRENT_CHAT",
        payload: currentChatUpdated,
      });
    } else {
      chat["isSaved"] = false; // idek if this does anything.
    }

    mutate({
      currentUser: currentUser,
      chatId: chat["id"],
    });
  };

  const savedChatsQuery = useSavedChatsQuery({
    currentUser: currentUser,
    targetLanguage: currentTargetLanguage,
  });

  const loadChat = (chat) => {
    console.log("CHAAAT", chat);
    setChatStarted(true);
    dispatch({
      type: "UPDATE_CURRENT_CHAT",
      payload: chat,
    });
  };

  return (
    <>
      {mode == "main-page" ? (
        <div className="p-4 my-3 saved-chats-section">
          <h5>
            Saved chats{" "}
            <span className="badge saved-chats-amount-badge">
              {savedChatsQuery.data && savedChatsQuery.data.length}
            </span>
          </h5>

          <div className="mt-2">
            {savedChatsQuery.data && savedChatsQuery.data.length > 0 ? (
              <>
                <div className="row">
                  {savedChatsQuery.data.map((chat) => (
                    <div
                      key={chat["id"]}
                      className="col-6 col-md-4 col-lg-3 mt-1 p-0"
                      //
                    >
                      <div className="mx-1 ps-2 saved-chat-item">
                        <div key={chat["id"]} className="px-2 py-2 my-1 d-flex">
                          <div className="me-auto">
                            <div className="text-muted">
                              {chat["targetLanguage"]}
                            </div>
                            <strong>{chat["topic"]["title"]}</strong>
                          </div>
                          <div className="dropdown p-0 saved-chat-menu-btn">
                            <a
                              className="btn mx-1 my-0 py-0 px-1 dropdown-btn"
                              type="button"
                              data-bs-toggle="dropdown"
                              data-bs-auto-close="true"
                              data-bs-dismiss="dropdown"
                              // onClick={() => {}} // handleUnsaveChat(chat);
                            >
                              <TbDots className="saved-chat-icon" size="20px" />
                            </a>
                            <div className="dropdown-menu">
                              <li
                                className="dropdown-item dropdown-item-btn"
                                onClick={() => loadChat(chat)}
                              >
                                Open this chat
                              </li>
                              <li
                                className="dropdown-item dropdown-item-btn"
                                onClick={() => {
                                  handleUnsaveChat(chat);
                                }}
                              >
                                Delete
                              </li>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              "You have no saved chats!"
            )}
          </div>
        </div>
      ) : (
        <div className="p-2 mt-2">
          {" "}
          <strong>
            Saved chats{" "}
            <span className="badge saved-chats-amount-badge">
              {savedChatsQuery.data && savedChatsQuery.data.length}
            </span>
          </strong>
          <div className="mt-2">
            {savedChatsQuery.data && savedChatsQuery.data.length > 0 ? (
              <div>
                {savedChatsQuery.data.map((chat) => (
                  <div key={chat["id"]} className="ps-2 mt-1 saved-chat-item">
                    <div className="px-0 py-1 d-flex">
                      <div className="me-auto">
                        <div className="text-muted">
                          {chat["targetLanguage"]}
                        </div>
                        <strong>{chat["topic"]["title"]}</strong>
                      </div>
                      <div className="dropdown p-0 saved-chat-menu-btn">
                        <a
                          className="btn mx-1 my-0 py-0 px-1 dropdown-btn"
                          type="button"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="true"
                          data-bs-dismiss="dropdown"
                          // onClick={() => {}} // handleUnsaveChat(chat);
                        >
                          <TbDots className="saved-chat-icon" size="20px" />
                        </a>
                        <div className="dropdown-menu">
                          <li
                            className="dropdown-item dropdown-item-btn"
                            onClick={() => loadChat(chat)}
                          >
                            Open this chat
                          </li>
                          <li
                            className="dropdown-item dropdown-item-btn"
                            onClick={() => {
                              handleUnsaveChat(chat);
                            }}
                          >
                            Delete
                          </li>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              "You have no saved chats!"
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default SavedChatsSection;
