import React, { useState, useContext } from "react";
import { Link, Navigate } from "react-router-dom";
import { useAuth } from "./context/AuthContext";
import { AppContext } from "./context/AppContext";
import ChatSection from "./ChatSection";
// import Navbar from "./Navbar";
import SavedChatsSection from "./SavedChatsSection";
import { TbBookmark, TbArrowBigLeft, TbSettings } from "react-icons/tb";
import TutorPanel from "./TutorPanel";
// import { TwitterShareButton, TwitterIcon } from "react-share";
import "./ChatPage.css";

function MainPage() {
  const [isSidebarActive, setIsSidebarActive] = useState(true);
  const [sidebarActiveItem, setSidebarActiveItem] = useState("settings");
  const [tutorPanelActive, setTutorPanelActive] = useState(false);
  const [chatStarted, setChatStarted] = useState(false);
  const { dispatch, currentTargetLanguage, currentChat, constants } =
    useContext(AppContext);

  const { currentUser } = useAuth();
  /*
                <div className="col-12 col-lg-3">
                <div className="message-info-section-user py-5 px-4">
                  {currentUserMessage ? (
                    currentUserMessage.errors &&
                    currentUserMessage.errors.length > 0 &&
                    !currentUserMessage.wasCorrect ? ( // careful w this line. Errors like "Avengers is not a drama movie" will cause the msg icon to be ⚠️ and the correction to be the same as the original msg. I need to refine the prompt so it doesn't throw these errors where the correction is the same as the original msg.
                      <>
                        <h4 className="pb-2">⚠️Attention</h4>
                        {currentUserMessage?.correctedMessage && (
                          <>
                            <strong>Corrected message</strong>
                            <div>{currentUserMessage?.correctedMessage}</div>
                          </>
                        )}

                        <div className="pt-3">
                          <strong className="">
                            We found {currentUserMessage?.errors.length}{" "}
                            improvement(s)
                          </strong>

                          <div>
                            {currentUserMessage.errors.map(
                              (errorObject, index) => (
                                <div
                                  key={index}
                                  className="error-detail p-3 mt-2"
                                >
                                  <strong>{errorObject.error}</strong>
                                  <div>{errorObject.explanation}</div>
                                </div>
                              )
                            )}
                          </div>
                          <hr className="mt-4 mb-2" />
                          <small className="text-muted">
                            This feature is in
                            <span className="badge rounded-pill px-3 ms-1 feature-tag coming-soon">
                              Beta
                            </span>
                            , which means some of the language errors listed may
                            ocassionally be incorrect or redundant.
                          </small>
                        </div>
                      </>
                    ) : (
                      <>
                        <h4>🥳All good!</h4>
                        <div>This message seems to have no errors.</div>
                      </>
                    )
                  ) : (
                    <div>
                      ✋ Here you'll be able to find tips and correction to
                      improve your conversational skills
                    </div>
                  )}
                </div>
              </div>*/

  const handleToggle = (type) => {
    if (type == "toggle-audio") {
      console.log("toggling");
      console.log(currentChat.chatSettings.audio);
      dispatch({
        type: "UPDATE_CHAT_SETTINGS",
        payload: {
          ...currentChat.chatSettings,
          audio: !currentChat.chatSettings.audio,
        },
      });
    } else if (type == "toggle-phonetic") {
      dispatch({
        type: "UPDATE_CHAT_SETTINGS",
        payload: {
          ...currentChat.chatSettings,
          phonetic: !currentChat.chatSettings?.phonetic,
        },
      });
    } else if (type == "toggle-translations") {
      dispatch({
        type: "UPDATE_CHAT_SETTINGS",
        payload: {
          ...currentChat.chatSettings,
          translations: !currentChat.chatSettings?.translations,
        },
      });
    }
  };

  return !currentUser ? (
    <Navigate to="/signup" replace={true} />
  ) : !currentTargetLanguage ? (
    <Navigate to="/dashboard" replace={true} />
  ) : (
    <div className="justify-content-center vh-100">
      <div className="main-page-container h-100">
        {/* <Navbar /> */}

        <div className="d-flex h-100">
          {/* SIDEBAR */}
          <div
            className={`d-none d-md-flex flex-column sidebar pt-4 ${
              isSidebarActive ? "active px-3" : "px-2"
            }`}
          >
            <Link to="/dashboard">
              <div id="go-back-btn" className="py-2 text-center">
                <TbArrowBigLeft size="24px" />
              </div>
            </Link>

            <button
              className={`btn py-2 mt-2 ${
                isSidebarActive && sidebarActiveItem == "bookmark"
                  ? "selected"
                  : ""
              }`}
              id="bookmark-btn-sidebar"
              type="button"
              onClick={() => {
                setSidebarActiveItem("bookmark");
                if (sidebarActiveItem == "bookmark" || !isSidebarActive) {
                  setIsSidebarActive(!isSidebarActive);
                }
              }}
            >
              <strong className="d-flex">
                <TbBookmark size="24px" />
                <div className={`m-auto ${isSidebarActive ? "" : "d-none"}`}>
                  Saved chats
                </div>
              </strong>
            </button>
            <div
              className={` ${
                isSidebarActive && sidebarActiveItem == "bookmark"
                  ? ""
                  : "d-none"
              }`}
            >
              <SavedChatsSection
                setChatStarted={setChatStarted}
                mode="sidebar"
              />
            </div>
            <button
              className={`btn py-2 mt-2 ${
                isSidebarActive && sidebarActiveItem == "settings"
                  ? "selected"
                  : ""
              }`}
              id="settings-btn-sidebar"
              type="button"
              onClick={() => {
                setSidebarActiveItem("settings");
                if (sidebarActiveItem == "settings" || !isSidebarActive) {
                  setIsSidebarActive(!isSidebarActive);
                }
              }}
            >
              <strong className="d-flex">
                <TbSettings size="24px" />
                <div className={`m-auto ${isSidebarActive ? "" : "d-none"}`}>
                  Chat settings
                </div>
              </strong>
            </button>
            <div
              className={`px-3 py-3 m-1 chat-settings-container ${
                isSidebarActive && sidebarActiveItem == "settings"
                  ? ""
                  : "d-none"
              }`}
            >
              {" "}
              <div className="py-1 d-flex align-items-center">
                <div
                  className={`slider-exterior ${
                    currentChat.chatSettings?.translations
                      ? "active"
                      : "inactive"
                  }`}
                  onClick={() => handleToggle("toggle-translations")}
                >
                  <div className="slider-interior" />
                </div>
                <div className="px-2">Translations</div>
              </div>
              <div className="sliders-section">
                {constants.elevenLabsLanguages.includes(
                  currentTargetLanguage
                ) && (
                  <div className="py-1 d-flex align-items-center">
                    <div
                      className={`slider-exterior ${
                        currentChat.chatSettings?.audio ? "active" : "inactive"
                      }`}
                      onClick={() => handleToggle("toggle-audio")}
                    >
                      <div className="slider-interior" />
                    </div>
                    <div className="px-2">Audio</div>
                  </div>
                )}
                {Object.keys(constants.differentScriptLanguages).includes(
                  currentTargetLanguage
                ) && (
                  <div className="py-1 d-flex align-items-center">
                    <div
                      className={`slider-exterior ${
                        currentChat.chatSettings?.phonetic
                          ? "active"
                          : "inactive"
                      }`}
                      onClick={() => handleToggle("toggle-phonetic")}
                    >
                      <div className="slider-interior" />
                    </div>
                    <div className="px-2">
                      {
                        constants.differentScriptLanguages[
                          currentTargetLanguage
                        ]
                      }
                    </div>
                  </div>
                )}
              </div>
              <div className="pt-3">
                <strong>Chat background</strong>
              </div>
              <div className="row p-2">
                {constants.backgrounds.map(({ imgName, imgId }, index) => (
                  <div
                    key={index}
                    className="col-6 p-1"
                    onClick={() =>
                      dispatch({
                        type: "UPDATE_CHAT_SETTINGS",
                        payload: {
                          ...currentChat.chatSettings,
                          backgroundImage: imgId,
                        },
                      })
                    }
                  >
                    <div
                      className={`chat-bg-miniature d-flex align-items-center justify-content-center ${imgId} ${
                        currentChat.chatSettings.backgroundImage == imgId
                          ? "selected"
                          : ""
                      }`}
                    >
                      {imgName}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="w-100 ">
            <div className="p-2 pt-0 p-sm-2 h-100 ">
              {" "}
              {/* px-0 py-0 p-sm-3 p-md-4 p-lg-4 p-xl-5 esto es para el div anterior */}
              {/* 
              <div className="container d-flex flex-column px-4 mb-2 pb-2 align-items-center">
                <h1 className="my-3 text-center">Let's get started!</h1>
                <div className="secondary-title pt-2 pb-5 text-center">
                  Learn languages by having natural, real, and open-ended
                  conversations with your very own AI language tutor.
                </div>
              </div>
              */}
              <div className="container-fluid w-100 h-100">
                {/* <div className="d-md-none container">
                  <SavedChatsSection
                    setChatStarted={setChatStarted}
                    mode="main-page"
                  />
                </div> */}

                <div className="row justify-content-center h-100">
                  {/* <div className="col-12 px-sm-2 d-none d-md-block">
                      <FeedbackFormBtn />
                    </div> */}
                  {/* <div className="col-12 d-none">
                      <TwitterShareButton url={"https://imgur.com/a/tgvM7v7"}>
                        <TwitterIcon size={32} round />
                      </TwitterShareButton>
                    </div> */}
                  <div className="row col-12 px-0 px-sm-2">
                    <div className="col px-0 px-sm-2">
                      <ChatSection
                        tutorPanelActive={tutorPanelActive}
                        setTutorPanelActive={setTutorPanelActive}
                        chatStarted={chatStarted}
                        setChatStarted={setChatStarted}
                      />
                    </div>
                    <div className="col-auto px-0">
                      <TutorPanel
                        tutorPanelActive={tutorPanelActive}
                        setTutorPanelActive={setTutorPanelActive}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainPage;
