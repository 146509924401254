import React, { useRef, useState } from "react";
import "./LoginSignup.css";

import { useAuth } from "../context/AuthContext";
import { Link, useNavigate, Navigate } from "react-router-dom";
import logo_white from "../images/logo_white.png";
import logo from "../images/logo.png";

function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  async function handleSubmit(e) {
    console.log("here");
    e.preventDefault();

    login(emailRef.current.value, passwordRef.current.value)
      .then((currentUserData) => {
        setError("");
        setLoading(true);
        navigate("/dashboard");
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            userId: currentUserData.user.multiFactor.user.uid,
          }),
        };

        fetch(`${process.env.REACT_APP_API_URL}/create_user`, options)
          .then((response) => response.json())
          .then((res) => console.log(res))
          .catch((err) => console.log("[LOGIN ERROR]", err));
      })
      .catch((err) => {
        console.error(err);
        setError("Failed to log in");
      });
    setLoading(false);
  }

  return currentUser ? (
    <Navigate to="/chat" replace={true} />
  ) : (
    <div className="row">
      <div className="col-12 col-md-5 login-image-container p-4 mx-0">
        <div className="img-text px-4 py-5 d-flex justify-content-start flex-column">
          <Link to="/">
            <img className="logo py-4" src={logo_white} alt="logo" />
          </Link>

          <div className="pt-4">
            <h1>Meet your AI Language Partner</h1>
            <p className="loginpage-subtitles">
              Practice languages by having natural and open-ended conversations.
            </p>
          </div>
        </div>
      </div>
      <div className="col-12 column col-md-7 px-0 mx-0">
        <div className="container subcontainer mt-5 d-md-flex align-items-center justify-content-center auth-container">
          <div className="w-100 px-3 auth-sub-container">
            <div className="card pb-4 px-3">
              <div className="card-body d-flex flex-column justify-content-center">
                <h2 className="card-title text-center">Welcome back! 👋</h2>
                {error && <div className="alert alert-danger">{error}</div>}
                <div>
                  <form className="form" onSubmit={handleSubmit}>
                    <div className="form-group m-2 p-2" id="email">
                      <div className="form-label">Email</div>
                      <input
                        className="form-control"
                        type="email"
                        ref={emailRef}
                        required
                        placeholder="name@example.com"
                      />
                    </div>
                    <div className="form-group m-2 p-2" id="password">
                      <div className="form-label">Password</div>
                      <input
                        className="form-control"
                        type="password"
                        ref={passwordRef}
                        required
                      />
                    </div>

                    <div className="m-2 p-2">
                      <button
                        className="btn w-100 mt-2 submit-btn"
                        type="submit"
                        disabled={loading}
                      >
                        Let's keep practicing!
                      </button>
                    </div>
                  </form>
                  <div className="w-100 text-center mt-2">
                    <Link to="/forgot-password">Forgot your password?</Link>
                  </div>
                </div>
                <div className="w-100 text-center mt-2">
                  Don't have an account yet?{" "}
                  <Link to="/signup">
                    <strong>Sign Up!</strong>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
