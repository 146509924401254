import "./globals.css"
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.min.js";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  // Navigate,
} from "react-router-dom";
import { AppProvider } from "./context/AppContext";
import { AuthProvider } from "./context/AuthContext";
import ChatPage from "./ChatPage";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import ForgotPassword from "./pages/ForgotPassword";
// import RedirectRoute from "./RedirectRoute";
import UpdateProfile from "./pages/UpdateProfile";
import LandingPage from "./pages/LandingPage";
import Plans from "./pages/Plans";
import Error404Page from "./pages/Error404Page";
import Dashboard from "./pages/Dashboard";
import Vocab from "./pages/Vocab";
// import InstallPrompt from "./utilities/InstallPrompt";

const queryClient = new QueryClient();

function App() {
  return (
    <AuthProvider>
      <AppProvider>
        <QueryClientProvider client={queryClient}>
          {/* <InstallPrompt /> */}
          <Router>
            <Routes>
              <Route path="/" element={<LandingPage />} />

              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/chat" element={<ChatPage />} />
              <Route path="/vocab" element={<Vocab />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/update-profile" element={<UpdateProfile />} />
              <Route path="/plans" element={<Plans />} />
              <Route path="*" element={<Error404Page />} />
            </Routes>
          </Router>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </AppProvider>
    </AuthProvider>
  );
}

export default App;
