import React, { useState, useContext } from "react";
import { AppContext } from "./context/AppContext";
import "./AddLanguageModal.css";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { addLanguage } from "./api/apiFunctions";
import { useAuth } from "./context/AuthContext";

function AddLanguageModal(props) {
  const [selectedLanguage, setSelectedLanguage] = useState("Spanish");
  const { dispatch, coursesList, constants } = useContext(AppContext);
  const queryClient = useQueryClient();
  const { currentUser } = useAuth();

  const addLanguageMutation = useMutation({
    mutationFn: addLanguage,
    onError: () => {
      console.log("Error adding language");
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["coursesList"] });
    },
    retry: false,
  });

  const addUserLanguage = () => {
    if (!coursesList.includes(selectedLanguage)) {
      // TODO: Use a Mutation to add the selectedLanguage to the list of languages that the user is learning
      addLanguageMutation.mutate({
        currentUser: currentUser,
        language: selectedLanguage,
      });
    } else {
      console.log("The course already exists!");
    }
    dispatch({
      type: "UPDATE_CURRENT_TARGET_LANGUAGE",
      payload: selectedLanguage,
    });
  };

  return (
    <div
      id={props.id}
      className="modal fade"
      tabIndex="-1"
      aria-labelledby="add-language-modal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content p-4 text-center">
          <h4 className="modal-title">Add a language</h4>

          <div className="modal-body">
            <div className="row d-flex justify-content-center ps-1">
              {constants.supportedLanguages.map((language, index) => (
                <div
                  id={index}
                  key={language}
                  className={`col-auto me-1 supported-language-pill ${
                    language == selectedLanguage ? "selected" : ""
                  }`}
                  onClick={() => setSelectedLanguage(language)}
                >
                  {language}
                </div>
              ))}
            </div>
            <div className="mt-4">
              <button
                id="submit-add-language-btn"
                className="btn btn-light px-3"
                data-bs-dismiss="modal"
                onClick={addUserLanguage}
              >
                <strong>Let's begin</strong>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddLanguageModal;
