import React from "react";
import { Link, useNavigate } from "react-router-dom";

function SubscribeModal() {
  const navigate = useNavigate();
  return (
    <div
      className="modal fade"
      id="subscribe-modal"
      tabIndex="-1"
      aria-labelledby="subscribe-modal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content p-4 text-center">
          <h4 className="modal-title" id="">
            Get access to all future updates
          </h4>

          <div className="modal-body">
            <div className="px-2">
              <p className="text-center">
                Since the beginning, Versa has been a free tool. And while it
                will keep being that way, we need your support to take it
                further.
              </p>
              <p>
                If you've enjoyed using Versa, consider supporting the project
                by getting this discounted subscription, which will give you
                access to all future updates and features. It's limited to the
                first 50 people.
              </p>
            </div>
          </div>

          <a
            className="p-0"
            href="https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-1NV55779SH191880CMS3P63Q"
            target="_blank"
          >
            <button className="btn ps-3 pe-4 subscribe-btn">
              <span className="subscribe-btn-text">
                <strong>⭐ Subscribe for $5/mo</strong>
              </span>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default SubscribeModal;
