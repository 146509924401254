import React, { useState, useContext } from "react";
import { AppContext } from "./context/AppContext";
import { useAuth } from "./context/AuthContext";
import { useMutation, useQueryClient } from "react-query";
import { saveChat, unsaveChat } from "./api/apiFunctions";
import { TbBookmark } from "react-icons/tb";
import SignupModal from "./SignupModal";

function SaveChatBtn() {
  const { currentChat, dispatch } = useContext(AppContext);
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();

  const saveChatMutation = useMutation({
    mutationFn: saveChat,
    onError: () => {
      console.log("Error saving the chat");
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["savedChats"] });
    },
    retry: false,
  });

  const unsaveChatMutation = useMutation({
    mutationFn: unsaveChat,
    onError: () => {
      console.log("Error UNsaving the chat");
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["savedChats"] });
    },
    retry: false,
  });

  const handleSaveChat = () => {
    console.log("⭐", currentChat);
    const currentChatUpdated = { ...currentChat, isSaved: true };
    saveChatMutation.mutate({
      currentUser: currentUser,
      currentChat: currentChatUpdated,
    });
    dispatch({ type: "UPDATE_CURRENT_CHAT", payload: currentChatUpdated });
  };

  const handleUnsaveChat = () => {
    const currentChatUpdated = { ...currentChat, isSaved: false };
    dispatch({ type: "UPDATE_CURRENT_CHAT", payload: currentChatUpdated });

    // Esto queda pendiente. Tengo que llamar a un mutate diferente, pero está medio complicado pq no puedo
    // tener 2 mutate en una pag (no quiero). Pero lo que puedo hacer es cambiar las apiFunctions
    // de "save" y "unsave", por una sola de "toggleSaveChat"

    /*TODO: CHECK IF THE UNSAVE FUNCTION REQUIRES THIS PARAMETERS OR OTHERS!*/
    unsaveChatMutation.mutate({
      currentUser: currentUser,
      targetLanguage: currentChat["targetLanguage"],
      chatId: currentChat["id"],
    });
  };

  const handleBookmarkBtn = () => {
    if (!currentUser) {
      // Handle what would happen if there's not current user
    } else if (currentChat.isSaved) {
      handleUnsaveChat();
    } else {
      handleSaveChat();
    }
  };

  //   <button
  //   className={`btn ms-2 py-2 ${
  //     currentChat["isSaved"] ? "bookmark-btn-selected" : ""
  //   }`}
  //   id="bookmark-btn"
  //   type="button"
  //   onClick={handleBookmarkBtn}
  // >
  //   <TbBookmark size="20px" />
  // </button>

  return (
    <button
      className={`btn py-sm-2 ${
        currentChat["isSaved"] ? "bookmark-btn-selected" : ""
      }`}
      id="bookmark-btn"
      type="button"
      onClick={handleBookmarkBtn}
    >
      <TbBookmark size="22px" />
    </button>
  );
}

export default SaveChatBtn;
