import React, { useContext } from "react";
import { AppContext } from "./context/AppContext";

function SettingsModal(props) {
  const { dispatch, currentChat, currentTargetLanguage, constants } =
    useContext(AppContext);

  const handleToggle = (type) => {
    if (type == "toggle-audio") {
      console.log("toggling");
      console.log(currentChat.chatSettings.audio);
      dispatch({
        type: "UPDATE_CHAT_SETTINGS",
        payload: {
          ...currentChat.chatSettings,
          audio: !currentChat.chatSettings.audio,
        },
      });
    } else if (type == "toggle-phonetic") {
      dispatch({
        type: "UPDATE_CHAT_SETTINGS",
        payload: {
          ...currentChat.chatSettings,
          phonetic: !currentChat.chatSettings?.phonetic,
        },
      });
    } else if (type == "toggle-translations") {
      dispatch({
        type: "UPDATE_CHAT_SETTINGS",
        payload: {
          ...currentChat.chatSettings,
          translations: !currentChat.chatSettings?.translations,
        },
      });
    }
  };

  return (
    <div
      id={props.id}
      className="modal fade"
      tabIndex="-1"
      aria-labelledby="settings-modal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content py-4 text-center">
          <h4 className="modal-title">Chat settings</h4>

          <div className="modal-body">
            <div className="px-4 py-2 chat-settings-container">
              {" "}
              <div className="py-1 d-flex align-items-center">
                <div
                  className={`slider-exterior ${
                    currentChat.chatSettings?.translations
                      ? "active"
                      : "inactive"
                  }`}
                  onClick={() => handleToggle("toggle-translations")}
                >
                  <div className="slider-interior" />
                </div>
                <div className="px-2">Translations</div>
              </div>
              <div className="sliders-section">
                {constants.elevenLabsLanguages.includes(
                  currentTargetLanguage
                ) && (
                  <div className="py-1 d-flex align-items-center">
                    <div
                      className={`slider-exterior ${
                        currentChat.chatSettings?.audio ? "active" : "inactive"
                      }`}
                      onClick={() => handleToggle("toggle-audio")}
                    >
                      <div className="slider-interior" />
                    </div>
                    <div className="px-2">Audio</div>
                  </div>
                )}
                {Object.keys(constants.differentScriptLanguages).includes(
                  currentTargetLanguage
                ) && (
                  <div className="py-1 d-flex align-items-center">
                    <div
                      className={`slider-exterior ${
                        currentChat.chatSettings?.phonetic
                          ? "active"
                          : "inactive"
                      }`}
                      onClick={() => handleToggle("toggle-phonetic")}
                    >
                      <div className="slider-interior" />
                    </div>
                    <div className="px-2">
                      {
                        constants.differentScriptLanguages[
                          currentTargetLanguage
                        ]
                      }
                    </div>
                  </div>
                )}
              </div>
              <div className="pt-3 text-start">
                <strong>Chat background</strong>
              </div>
              <div className="row py-2">
                {constants.backgrounds.map(({ imgName, imgId }, index) => (
                  <div
                    key={index}
                    className="col-4 p-1"
                    onClick={() =>
                      dispatch({
                        type: "UPDATE_CHAT_SETTINGS",
                        payload: {
                          ...currentChat.chatSettings,
                          backgroundImage: imgId,
                        },
                      })
                    }
                  >
                    <div
                      className={`chat-bg-miniature d-flex align-items-center justify-content-center ${imgId} ${
                        currentChat.chatSettings.backgroundImage == imgId
                          ? "selected"
                          : ""
                      }`}
                    >
                      {imgName}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingsModal;
