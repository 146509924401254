import React from "react";
import "./TypingAnimation.css";

function TypingAnimation() {
  return (
    <div className="typing">
      <div className="typing__dot"></div>
      <div className="typing__dot"></div>
      <div className="typing__dot"></div>
    </div>
  );
}

export default TypingAnimation;
