import React, { useContext } from "react";
import "./ResourcesSection.css";
import AddResourceModal from "../AddResourceModal";
import { TbCirclePlus } from "react-icons/tb";
import { AppContext } from "../context/AppContext";

function ResourcesSection() {
  const comingSoon = true;
  const { currentTargetLanguage } = useContext(AppContext);

  const resources = [
    { resName: "lucrezia", link: "https://www.youtube.com/@lucreziaoddone" },
    {
      resName: "some video",
      link: "https://www.youtube.com/watch?v=EG9x0eevbV4",
    },
    { resName: "lucrezia", link: "https://www.youtube.com/@lucreziaoddone" },
    {
      resName: "some video",
      link: "https://www.youtube.com/watch?v=EG9x0eevbV4",
    },
    { resName: "lucrezia", link: "https://www.youtube.com/@lucreziaoddone" },
    {
      resName: "some video",
      link: "https://www.youtube.com/watch?v=EG9x0eevbV4",
    },
  ];
  return (
    <div
      className={`drill-card-container card h-100 ${
        comingSoon ? "coming-soon" : ""
      }`}
    >
      {!comingSoon ? (
        <>
          <h4>Resources</h4>
          <div>Add your favorite resources for easy access.</div>
          <div className="row pt-2">
            {resources.map((item, index) => (
              <div key={index} className="col-3 p-1">
                <a href={item["link"]} target="_blank">
                  <div className="resource-miniature">{item["resName"]}</div>
                </a>{" "}
              </div>
            ))}
            <div className="col-3 p-1">
              <button
                className="resource-miniature w-100"
                id="add-resource-btn"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#add-resource-modal"
              >
                hi
              </button>
            </div>
            <AddResourceModal id="add-resource-modal" />
          </div>
        </>
      ) : (
        <>
          <div className="px-4 pb-4 pt-4 card-body coming-soon-body">
            <h4>Resources</h4>
            <div className="py-0">
              Add your favorite {currentTargetLanguage} learning resources for
              easy access.
            </div>
            <div className="coming-soon-container">
              <div className="row pt-3">
                <div className="col-3 pe-1">
                  <button
                    className="resource-miniature disabled w-100"
                    id="add-resource-btn"
                    disabled
                  >
                    <TbCirclePlus size="22px" />
                  </button>
                </div>
              </div>
            </div>
            <div className="pt-3 px-2 row">
              <div className="coming-soon-badge px-3 py-1 col-auto">
                coming soon
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ResourcesSection;
