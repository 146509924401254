// Remember to add the "compat", otherwise it won't work with this version of firebase.
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

console.log("🗝️", process.env.REACT_APP_API_URL);
const app = firebase.initializeApp({
  // These should be later changed to the production environment variables.
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

export const auth = app.auth();
export default app;
